import React, { useState, useRef, useEffect } from "react";
import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import validator from "email-validator";
import firebase from "../config/firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { withRouter, Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import dayjs from "dayjs";

const LoginWithEmailAndPassword = (props) => {
  const { history } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "",
    timeout: 12000,
    open: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setUserMessage({ message: "", severity: "", open: false });
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (componentIsMounted.current) {
      setUserMessage({ message: "", severity: "", open: false });
      setIsLoading(true);
    }
    if (validator.validate(email)) {
      if (password.length > 5) {
        let user;
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            user = userCredential.user;
            return firebase
              .firestore()
              .collection("notifications")
              .add({
                type: ["internal"],
                title: "New Sign in!",
                body: `New sign in recorded on ${dayjs().format(
                  "dddd, MMMM D, YYYY h:mm A"
                )} (email and password).`,
                uid: user.uid,
                click_action: null,
                created_at: new Date(),
                severity: "success",
                button_label: null,
                read: false,
              });
          })
          .then(() => {
            history.push("/dashboard", {
              notification: {
                message: `Your are now signed in with ${user.email}!`,
                severity: "success",
                timeout: 12000,
                status: true,
              },
            });
          })
          .catch((error) => {
            if (componentIsMounted.current) {
              setUserMessage({
                message: error.message,
                severity: "error",
                timeout: 12000,
                open: true,
              });
              setIsLoading(false);
            }
          });
      } else {
        if (componentIsMounted.current) {
          setUserMessage({
            message:
              "Password should be at least 6 characters. Please try again!",
            severity: "error",
            timeout: 12000,
            open: true,
          });
          setIsLoading(false);
        }
      }
    } else {
      if (componentIsMounted.current) {
        setUserMessage({
          message: "Please use a valid email!",
          severity: "error",
          timeout: 12000,
          open: true,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth className="form-control">
        <TextField
          id="email"
          type="email"
          onChange={handleChangeEmail}
          label="Email"
          variant="outlined"
        />
      </FormControl>
      <FormControl className="form-control" variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handleChangePassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>

      <Button
        className="login-button login-email"
        type="submit"
        endIcon={isLoading ? <CircularProgress size={18} /> : null}
      >
        <EmailTwoToneIcon fontSize="large" />
        Login with Email
      </Button>
      <div className="account-secondary-actions">
        <Link to="/password-reset">Forgot your password?</Link>
        <p>
          No account yet? <Link to="/sign-up">Sign up</Link>
        </p>
      </div>

      <Snackbar
        open={userMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default withRouter(LoginWithEmailAndPassword);
