import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAe-x5VgMXWbLfcR2gyqjIgz2HE9IUWic4",
  authDomain: "zbang-application-fd8de.firebaseapp.com",
  projectId: "zbang-application-fd8de",
  storageBucket: "zbang-application-fd8de.appspot.com",
  messagingSenderId: "976017865582",
  appId: "1:976017865582:web:cd36712913b9f516c9df7f",
  measurementId: "G-1VB3GWT041",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
