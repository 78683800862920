import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../config/AuthProvider";
import { Redirect } from "react-router-dom";
import Menu from "../components/Menu";
import Bar from "../components/Bar";
import Page from "../components/Page";
import { CircularProgress } from "@material-ui/core";
import firebase from "../config/firebase";
import NotificationList from "../components/NotificationList";
import BottomNavigationMobile from "../components/BottomNavigationMobile";

const Notifications = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const firestore = firebase.firestore();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [orderBy, setOrderBy] = useState("created_at");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [nextBatch, setNextBatch] = useState(
    firestore.collection("notifications").where("uid", "==", profile.uid)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [startAfterDoc, setStartAfterDoc] = useState(null);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(true);
  const [isNotificationsEmpty, setIsNotificationsEmpty] = useState(true);
  const [limit, setLimit] = useState(10);
  const [wasPaginated, setWasPaginated] = useState(false);
  const [scopedIsLoading, setScopedIsLoading] = useState(false);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handlePagination = () => {
    setIsNotificationsLoading(true);
    nextBatch
      .orderBy(orderBy, orderDirection)
      .startAfter(startAfterDoc)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        setStartAfterDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        let tempNotifications = notifications;
        let isEmpty = true;
        querySnapshot.forEach(function (doc) {
          isEmpty = false;
          tempNotifications.push({ ...doc.data(), id: doc.id });
        });
        if (componentIsMounted.current) {
          setIsNotificationsLoading(false);
          setIsNotificationsEmpty(isEmpty);
          setNotifications(tempNotifications);
          setCurrentPage(currentPage + 1);
          setWasPaginated(true);
        }
      })
      .catch((error) => {
        if (componentIsMounted.current) {
          setIsNotificationsLoading(false);
        }
      });
  };

  useEffect(() => {
    nextBatch
      .orderBy(orderBy, orderDirection)
      .limit(limit * currentPage)
      .onSnapshot(function (querySnapshot) {
        setStartAfterDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        let tempNotifications = [];
        let isEmpty = true;
        querySnapshot.forEach(function (doc) {
          isEmpty = false;
          tempNotifications.push({ ...doc.data(), id: doc.id });
        });
        if (componentIsMounted.current) {
          setIsNotificationsLoading(false);
          setIsNotificationsEmpty(isEmpty);
          setNotifications(tempNotifications);
        }
      });
  }, [limit, orderBy, orderDirection]);

  useEffect(() => {
    const firestore = firebase.firestore();

    let promisses = [];
    notifications.forEach((notification) => {
      if (!notification.read) {
        promisses.push(
          firestore
            .collection("notifications")
            .doc(notification.id)
            .set({ read: true, read_at: new Date() }, { merge: true })
        );
      }
    });

    Promise.all(promisses)
      .then(() => {
        if (promisses.length === 0) {
          console.log("There are no unread notifications");
        } else {
          console.log("All Notifications read");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [notifications.length]);

  if (!profile.is_facebook_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-facebook" />
      </React.Fragment>
    );
  }

  if (!profile.is_email_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-email" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Menu
        profile={profile}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        window={window}
        claims={currentUser.claims}
      />
      <Bar
        title={
          notifications.isLoading ? (
            <span>
              Notifications <CircularProgress size={18} />
            </span>
          ) : (
            "Notifications"
          )
        }
        actions={null}
        handleDrawerToggle={handleDrawerToggle}
      />
      <BottomNavigationMobile />
      <Page>
        <div className="page-no-sidebar">
          <NotificationList
            notifications={notifications}
            handlePagination={handlePagination}
            wasPaginated={wasPaginated}
            startAfterDoc={startAfterDoc}
            currentPage={currentPage}
            limit={limit}
            setLimit={setLimit}
            isLoading={isNotificationsLoading}
            isEmpty={isNotificationsEmpty}
            isLastPage={notifications.length !== currentPage * limit}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            profile={profile}
          />
        </div>
      </Page>
    </React.Fragment>
  );
};

export default Notifications;
