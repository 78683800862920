import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import validator from "email-validator";
import firebase from "../config/firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { withRouter, Link } from "react-router-dom";

const PasswordResetForm = (props) => {
  const { history } = props;
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState({ message: "", type: "" });
  const [open, setOpen] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(null);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (componentIsMounted.current) {
      setOpen(false);
    }
    if (validator.validate(email)) {
      const firestore = firebase.firestore();
      new Promise((resolve, reject) => {
        firebase
          .auth()
          .sendPasswordResetEmail(email)
          .then(() => {
            if (componentIsMounted.current) {
              setUserMessage({
                message: "Please check your email!",
                type: "success",
              });
              setOpen(true);
            }
            history.push("/", {
              notification: {
                message:
                  "Please follow the instructions we've sent to your email.",
                severity: "success",
                timeout: 12000,
                status: true,
              },
            });
            resolve(email);
          })
          .catch((error) => {
            console.log("Error", error);
            if (componentIsMounted.current) {
              setUserMessage({
                message: error.message,
                type: "error",
              });
              setOpen(true);
            }
            reject(error);
          });
      });
    } else {
      if (componentIsMounted.current) {
        setUserMessage({
          message: "Please use a valid email!",
          type: "error",
        });
        setOpen(true);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth className="form-control">
        <TextField
          id="email"
          type="email"
          onChange={handleChangeEmail}
          label="Email"
          variant="outlined"
        />
      </FormControl>

      <Button className="login-button login-email" type="submit">
        Reset password
      </Button>
      <div className="account-secondary-actions">
        <p>
          No account yet? <Link to="/sign-up">Sign up</Link>
        </p>
      </div>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={userMessage.type}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default withRouter(PasswordResetForm);
