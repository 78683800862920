import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../config/AuthProvider";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import DashboardIcon from "./DashboardIcon";
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import { NotificationsActiveTwoTone } from "@material-ui/icons";
import Badge from "@material-ui/core/Badge";
import firebase from "../config/firebase";

const BottomNavigationMobile = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const [notificationsCount, setNotificationsCount] = useState(0);

  useEffect(() => {
    firebase
      .firestore()
      .collection("notifications")
      .where("uid", "==", profile.uid)
      .where("read", "==", false)
      .limit(10)
      .onSnapshot((querySnapshot) => {
        setNotificationsCount(querySnapshot.size);
      });
  }, []);

  const initValue =
    props.location.pathname === "/settings"
      ? 3
      : props.location.pathname === "/notifications"
      ? 2
      : props.location.pathname === "/campaigns"
      ? 1
      : 0;
  const [value, setValue] = React.useState(initValue);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      className="bottom-navigation"
    >
      <BottomNavigationAction
        label="Dashboard"
        icon={<DashboardIcon />}
        onClick={() => {
          setValue(0);
          props.history.push("/dashboard");
        }}
      />
      <BottomNavigationAction
        label="Launcher"
        icon={<WhatshotTwoToneIcon />}
        onClick={() => {
          setValue(1);
          props.history.push("/campaigns");
        }}
      />
      <BottomNavigationAction
        label="Notifications"
        icon={
          <Badge
            badgeContent={
              notificationsCount > 0
                ? notificationsCount > 10
                  ? "10+"
                  : notificationsCount
                : null
            }
            color="primary"
          >
            <NotificationsActiveTwoTone />
          </Badge>
        }
        onClick={() => {
          setValue(1);
          props.history.push("/notifications");
        }}
      />
      <BottomNavigationAction
        label="Settings"
        icon={<SettingsTwoToneIcon />}
        onClick={() => {
          setValue(2);
          props.history.push("/settings");
        }}
      />
    </BottomNavigation>
  );
};

export default withRouter(BottomNavigationMobile);
