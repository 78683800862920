import React from "react";

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const ordinal = (number) => {
  switch (number) {
    case 1:
    case 21:
      return number + "st";
      break;
    case 2:
    case 22:
      return number + "nd";
      break;
    case 3:
    case 23:
      return number + "rd";
      break;
    default:
      return number + "th";
  }
};

export const date_preset_name = (date_preset) => {
  if (date_preset === "last_3d") {
    return "Last 3 days";
  }

  if (date_preset === "last_7d") {
    return "Last 7 days";
  }

  if (date_preset === "last_14d") {
    return "Last 14 days";
  }

  if (date_preset === "last_30d") {
    return "Last 30 days";
  }

  if (date_preset === "this_month") {
    return "This month";
  }

  if (date_preset === "last_month") {
    return "Last month";
  }

  if (date_preset === "last_90d") {
    return "Last 90 days";
  }

  if (date_preset === "this_year") {
    return "This year";
  }

  return null;
};

export const date_preset_length = (date_preset) => {
  if (date_preset === "last_3d") {
    return 3;
  }

  if (date_preset === "last_7d") {
    return 7;
  }

  if (date_preset === "last_14d") {
    return 14;
  }

  if (date_preset === "last_30d") {
    return 30;
  }

  if (date_preset === "this_month") {
    return 31;
  }

  if (date_preset === "last_month") {
    return 31;
  }

  if (date_preset === "last_90d") {
    return 90;
  }

  if (date_preset === "this_year") {
    return 365;
  }

  return null;
};

export const kFormatter = (num) => {
  return Math.abs(num) > 999999
    ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
    : Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
};
