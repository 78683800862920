import React, { useState, useEffect, useRef } from "react";
import firebase from "../config/firebase";
import Button from "@material-ui/core/BUtton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import _ from "lodash";

const SelectBusiness = (props) => {
  const { profile } = props;
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "",
    open: false,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (profile.selected_business) {
      setSelectedBusiness(profile.selected_business);
    }
  }, [profile]);

  const handleRequest = () => {
    if (componentIsMounted.current) {
      setIsLoading(true);
    }
    const functions = firebase.functions();
    const request = functions.httpsCallable("get_businesses");
    request()
      .then((result) => {
        const payload = result.data;
        if (componentIsMounted.current) {
          setUserMessage({
            message: "We're in business!",
            severity: "success",
            open: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (componentIsMounted.current) {
          setUserMessage({
            message: error.message,
            severity: "error",
            open: true,
          });
          setIsLoading(false);
        }
      });
  };

  const handleClose = () => {
    if (componentIsMounted.current) {
      setUserMessage({ message: "", severity: "", open: false });
    }
  };

  const handleSelectedBusiness = (event) => {
    const business_id = event.target.value;
    const business = _.find(profile.businesses, function (o) {
      return o.id == business_id;
    });

    if (componentIsMounted.current) {
      setSelectedBusiness(business);
    }
  };

  const handleUpdateSelectedBusiness = () => {
    setIsLoading(true);

    if (selectedBusiness) {
      const functions = firebase.functions();
      const request = functions.httpsCallable("update_selected_business");
      request({ business: selectedBusiness })
        .then((result) => {
          const payload = result.data;
          if (componentIsMounted.current) {
            setUserMessage({
              message: "Your selected Business Manager has been updated!",
              severity: "success",
              open: true,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (componentIsMounted.current) {
            setUserMessage({
              message: error.message,
              severity: "error",
              open: true,
            });
            setIsLoading(false);
          }
        });
    } else {
      if (componentIsMounted.current) {
        setUserMessage({
          message: "Please select a Business Manager",
          severity: "warning",
          open: true,
        });
      }
    }
  };

  if (profile.businesses) {
    return (
      <React.Fragment>
        <div>
          <div className="card">
            <div className="card-body">
              <React.Fragment>
                <div>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="selectedBusiness"
                      value={
                        selectedBusiness && selectedBusiness.id
                          ? selectedBusiness.id
                          : null
                      }
                      onChange={handleSelectedBusiness}
                    >
                      {profile.businesses.map((business) => {
                        return (
                          <FormControlLabel
                            key={business.id}
                            value={business.id}
                            control={<Radio />}
                            label={business.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
                {!selectedBusiness ? (
                  <p className="text-warning">Please selecte a business</p>
                ) : (
                  <br />
                )}

                <Button
                  onClick={handleUpdateSelectedBusiness}
                  variant="contained"
                  color="primary"
                  disabled={!selectedBusiness}
                  endIcon={isLoading ? <CircularProgress size={18} /> : null}
                >
                  Select Business Manager
                </Button>
              </React.Fragment>
            </div>
          </div>
        </div>
        <Snackbar
          open={userMessage.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={userMessage.severity}>
            {userMessage.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleRequest}
        endIcon={isLoading ? <CircularProgress size={18} /> : null}
      >
        View available businesses
      </Button>
      <Snackbar
        open={userMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default SelectBusiness;
