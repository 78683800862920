import React, { useState, useRef, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link, withRouter } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import ResourceMobileFilter from "./ResourceMobileFilter";
import dayjs from "dayjs";
import clsx from "clsx";
import { CheckTwoTone, ErrorTwoTone, WarningTwoTone } from "@material-ui/icons";

const NotificationList = (props) => {
  const {
    notifications,
    handlePagination,
    wasPaginated,
    startAfterDoc,
    currentPage,
    limit,
    setLimit,
    isLoading,
    isEmpty,
    isLastPage,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    profile,
  } = props;

  const [open, setOpen] = useState(false);
  const [scopedIsLoading, setScopedIsLoading] = useState(false);
  const [clickedCampaign, setClickedCampaign] = useState(null);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  if (isLoading && notifications.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="loading-component">
            <p>Gathering data</p> <CircularProgress size={20} />
          </div>
        </div>
      </div>
    );
  }

  if (!isLoading && isEmpty && notifications.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="empty-component">
            <p>You don't have any notifications yet.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="resource-table">
        {notifications.length > 0 ? (
          <Toolbar className="action-bar">
            <div className="item">
              <span>Per page</span>
              <Select
                id="per-page"
                value={limit}
                onChange={(event) => {
                  setLimit(event.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </div>
            <div className="item">
              <span>Order by</span>
              <Select
                id="order-by"
                value={orderBy}
                onChange={(event) => {
                  setOrderBy(event.target.value);
                }}
              >
                <MenuItem value="created_at">Date</MenuItem>
                <MenuItem value="type">Type</MenuItem>
              </Select>
            </div>
            <div className="item">
              <span>Direction</span>
              <Select
                id="order-direction"
                value={orderDirection}
                onChange={(event) => {
                  setOrderDirection(event.target.value);
                }}
              >
                <MenuItem value="desc">Descending</MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
              </Select>
            </div>
          </Toolbar>
        ) : null}

        <Toolbar className="mobile-resource-filter">
          <Button
            type="text"
            color="primary"
            startIcon={<FilterListIcon />}
            onClick={() => {
              setOpen(true);
            }}
          >
            Filter
          </Button>
        </Toolbar>

        <ul className="notifications-list">
          {notifications.map((notification) => {
            return (
              <li
                key={notification.id}
                className={clsx(
                  `severity-${notification.severity}`,
                  `read-${notification.read}`
                )}
              >
                {notification.severity === "success" ? (
                  <div className="icon">
                    <CheckTwoTone />
                  </div>
                ) : null}
                {notification.severity === "error" ? (
                  <div className="icon">
                    <ErrorTwoTone />
                  </div>
                ) : null}
                {notification.severity === "warning" ? (
                  <div className="icon">
                    <WarningTwoTone />
                  </div>
                ) : null}
                <div>
                  <span className="content">{notification.body}</span>
                  <span className="date">
                    <small>
                      {dayjs
                        .unix(notification.created_at.seconds)
                        .format("MMMM D, YYYY h:mm A")}
                    </small>
                    {notification.type &&
                    typeof notification.type === "object" ? (
                      <small className="notification-type">
                        Type: {notification.type.join(", ")}
                      </small>
                    ) : null}
                  </span>
                  {notification.click_action && notification.button_label ? (
                    <span>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          props.history.push(notification.click_action);
                        }}
                      >
                        {notification.button_label}
                      </Button>
                    </span>
                  ) : null}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {isLastPage && wasPaginated ? (
        <div className="pagination">
          <p style={{ margin: 0 }}>You've reached the end.</p>
        </div>
      ) : null}
      {isLastPage ? null : (
        <div className="pagination">
          <Button
            className="pagination-load-more"
            variant="outlined"
            onClick={handlePagination}
            endIcon={isLoading ? <CircularProgress size={18} /> : null}
          >
            Load more
          </Button>
        </div>
      )}
      <ResourceMobileFilter
        resources={notifications}
        limit={limit}
        setLimit={setLimit}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        open={open}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
};

export default withRouter(NotificationList);
