import React, { useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { CircularProgress } from "@material-ui/core";

const DatePreset = (props) => {
  const { preset, handlePresetChange, isLoading, classes } = props;

  return (
    <ul className={`date-preset-list ${classes}`}>
      <li
        className={
          preset === "last_3d"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                console.log("Should worl");
                handlePresetChange("last_3d");
              }
        }
      >
        <Tooltip title={"Last 3 days"}>
          <span>
            3D{" "}
            {isLoading && preset === "last_3d" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>
      <li
        className={
          preset === "last_7d"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                handlePresetChange("last_7d");
              }
        }
      >
        <Tooltip title="Last 7 days">
          <span>
            7D{" "}
            {isLoading && preset === "last_7d" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>
      <li
        className={
          preset === "last_14d"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                handlePresetChange("last_14d");
              }
        }
      >
        <Tooltip title="Last 14 days">
          <span>
            14D{" "}
            {isLoading && preset === "last_14d" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>
      <li
        className={
          preset === "last_30d"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                handlePresetChange("last_30d");
              }
        }
      >
        <Tooltip title="Last 30 days">
          <span>
            30D{" "}
            {isLoading && preset === "last_30d" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>
      <li
        className={
          preset === "this_month"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                handlePresetChange("this_month");
              }
        }
      >
        <Tooltip title="Month to Date">
          <span>
            MTD{" "}
            {isLoading && preset === "this_month" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>

      <li
        className={
          preset === "last_month"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                handlePresetChange("last_month");
              }
        }
      >
        <Tooltip title="Last Month">
          <span>
            LM{" "}
            {isLoading && preset === "last_month" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>
      <li
        className={
          preset === "last_90d"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                handlePresetChange("last_90d");
              }
        }
      >
        <Tooltip title="Last 90 days">
          <span>
            90D{" "}
            {isLoading && preset === "last_90d" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>
      <li
        className={
          preset === "this_year"
            ? `active loading-${isLoading}`
            : `loading-${isLoading}`
        }
        onClick={
          isLoading
            ? null
            : () => {
                handlePresetChange("this_year");
              }
        }
      >
        <Tooltip title="Year to date">
          <span>
            YTD{" "}
            {isLoading && preset === "this_year" ? (
              <CircularProgress size={13} />
            ) : null}
          </span>
        </Tooltip>
      </li>
    </ul>
  );
};

export default DatePreset;
