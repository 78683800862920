import React, { useEffect, useState } from "react";
import { useTheme, ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import DashboardIcon from "./DashboardIcon";
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import PowerSettingsNewTwoToneIcon from "@material-ui/icons/PowerSettingsNewTwoTone";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import logo from "../images/logo.svg";
import beta from "../images/beta.svg";
import firebase from "../config/firebase";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import UserAvatar from "./UserAvatar";
import AccountType from "./AccountType";
import Badge from "@material-ui/core/Badge";

const Menu = (props) => {
  const {
    profile,
    mobileOpen,
    handleDrawerToggle,
    window,
    report,
    setReport,
    claims,
  } = props;

  const [notificationsCount, setNotificationsCount] = useState(0);

  const goTo = (route) => {
    props.history.push(route);
  };

  useEffect(() => {
    let query = firebase.firestore().collection("notifications");
    query = query.where("uid", "==", profile.uid);
    query = query.where("read", "==", false);
    query = query.limit(10);
    query.onSnapshot((querySnapshot) => {
      setNotificationsCount(querySnapshot.size);
    });
  }, []);

  const handleAdAccountSelect = () => {
    if (props.location.pathname === "/dashboard") {
      setReport({ ...report, selected_product: null });
      const firestore = firebase.firestore();

      firestore
        .collection("reports")
        .doc(profile.uid)
        .update({ selected_product: null })
        .then(() => {
          console.log("ok");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      goTo("/dashboard");
    }
  };

  const drawer = (
    <div className="drawer">
      <div>
        <div
          className="toolbar logo-toolbar"
          onClick={
            report && report.selected_product ? handleAdAccountSelect : null
          }
        >
          <div className="logo-wraper">
            <img src={logo} alt="Zbang" height="24" />
          </div>
          <span className="brand-name">Zbang</span>
          <span>
            <img
              src={beta}
              alt="Zbang Beta"
              height="14"
              style={{
                opacity: 0.4,
                marginTop: "-8px",
                marginLeft: "2px",
              }}
            />
          </span>
        </div>

        <List className="menu">
          <ListItem
            button
            key="Dashboard"
            onClick={
              report && report.selected_product
                ? handleAdAccountSelect
                : () => {
                    goTo("/dashboard");
                  }
            }
          >
            <ListItemIcon>
              <DashboardIcon
                className={
                  props.location.pathname === "/dashboard"
                    ? "menu-icon active"
                    : "menu-icon"
                }
              />
            </ListItemIcon>
            <ListItemText
              className={
                props.location.pathname === "/dashboard"
                  ? "menu-icon active"
                  : "menu-icon"
              }
              primary="Dashboard"
            />
          </ListItem>
          <ListItem
            button
            key="Campaigns"
            onClick={() => {
              goTo("/campaigns");
            }}
          >
            <ListItemIcon>
              <WhatshotTwoToneIcon
                className={
                  props.location.pathname === "/campaigns" ||
                  props.location.pathname === "/campaigns/new"
                    ? "menu-icon active"
                    : "menu-icon"
                }
              />
            </ListItemIcon>
            <ListItemText
              className={
                props.location.pathname === "/campaigns" ||
                props.location.pathname === "/campaigns/new"
                  ? "menu-icon active"
                  : "menu-icon"
              }
              primary="Launcher"
            />
          </ListItem>
          <ListItem
            button
            key="Notifications"
            onClick={() => {
              goTo("/notifications");
            }}
          >
            <ListItemIcon>
              <Badge
                badgeContent={
                  notificationsCount > 0
                    ? notificationsCount > 10
                      ? "10+"
                      : notificationsCount
                    : null
                }
                color="primary"
              >
                <NotificationsActiveTwoToneIcon
                  className={
                    props.location.pathname === "/notifications"
                      ? "menu-icon active"
                      : "menu-icon"
                  }
                />
              </Badge>
            </ListItemIcon>
            <ListItemText
              className={
                props.location.pathname === "/notifications"
                  ? "menu-icon active"
                  : "menu-icon"
              }
              primary="Notifications"
            />
          </ListItem>
          <ListItem
            button
            key="Settings"
            onClick={() => {
              goTo("/settings");
            }}
          >
            <ListItemIcon>
              <SettingsTwoToneIcon
                className={
                  props.location.pathname === "/settings"
                    ? "menu-icon active"
                    : "menu-icon"
                }
              />
            </ListItemIcon>
            <ListItemText
              className={
                props.location.pathname === "/settings"
                  ? "menu-icon active"
                  : "menu-icon"
              }
              primary="Settings"
            />
          </ListItem>
          <br />
          <Divider />
          <br />

          <ListItem
            button
            key="Sign Out"
            onClick={() => {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  props.history.push("/dashboard", {
                    notification: {
                      message: `Your are now signed out!`,
                      severity: "warning",
                      timeout: 6000,
                      status: true,
                    },
                  });
                })
                .catch((error) => {
                  // An error happened.
                });
            }}
          >
            <ListItemIcon>
              <PowerSettingsNewTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
          <ListItem button key="version">
            <ListItemText
              className="menu-icon menu-version"
              style={{ opacity: 0.5 }}
              primary="v.1.0.0"
            />
          </ListItem>
        </List>
      </div>
      <div>
        <AccountType claims={claims} profile={profile} />
        <UserAvatar profile={profile} showEmail={false} />
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className="drawer-wrapper" aria-label="dasboard">
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          classes={{
            paper: "drawer-paper",
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: "drawer-paper",
          }}
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default withRouter(Menu);
