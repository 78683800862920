import React from "react";
import logo from "../images/logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const useSyles = makeStyles((theme) => {
  return {
    logo: {
      maxWidth: "70px",
      marginBottom: "30px",
      marginTop: "40px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    },
    title: {
      textAlign: "center",
      marginBottom: "30px",
      fontWeight: "normal",
      fontSize: "32px",
    },
  };
});

const Terms = (props) => {
  const classes = useSyles();
  return (
    <div className="legal-page">
      <Container maxWidth="md">
        <Grid container>
          <Grid item md={12}>
            <Link to="/">
              <img src={logo} alt="Zbang" className={classes.logo} />
            </Link>
            <h1 className={classes.title}>Terms and Conditions</h1>
            <p>
              <strong>Parties:</strong>
            </p>
            <ol>
              <li>
                NestPlace Ltd.(t/aZbang), acompany incorporated in England &
                Wales number 12942659 with a registered office address at 65
                London Wall, London, England, EC2M 5TU, further referred to as
                “We” or “Us” or “Our”, and
              </li>
              <li>
                the party submitting an application to become a user of Zbang,
                further referred to as “You” or “Your”.
              </li>
            </ol>
            <p>
              The following Terms and Conditions (“Terms”) regulate the access
              to and use of Services (as defined below). Please review the Terms
              carefully.
            </p>
            <p>
              In order to use Our services, You must be a registered user and
              have an advertising account on www.facebook.com (“Facebook”) or
              www.instagram.com (“Instagram”) and You must unconditionally
              consent to and accept these terms and conditions (including those
              regulating the processing of personal data) by ticking the “I
              agree” box when You sign up to the Services whereby You are
              entering into a legally binding agreement with Us.
            </p>
            <h3>1. Description of Zbang</h3>
            <p>
              The Service is a SaaS advertising tools and documentation and it
              is intended to assist in the creation, management, monitoring and
              optimization of advertising campaigns on the applicable Ad
              Networks.
            </p>
            <p>Ad Account” = customer’s account in the Ad Network.</p>
            <p>“Master Account” = main account where Ad Accounts are linked.</p>
            <p>
              “Ad Network” = advertising platform, such as Facebook, Google and
              so on.
            </p>
            <p>
              “Platform” = Zbang’s proprietary platform for management,
              planning, optimizing, monitoring and execution of advertising
              strategies and related activities and which is intended to provide
              You with the Service.
            </p>
            <h3>2. Access</h3>
            <p>
              We grant You, during the Term, a non-exclusive, non-transferable,
              revocable, right to access and use Service and access the Platform
              under these Terms. Access to the Platform and the Service requires
              You to register. As part of the signup process, You will be
              required to link certain Ad Accounts to the Master Account. You
              may, from time to time, remove or add Ad Accounts in its
              discretion. Once submitted and to the extent complied with all
              requirements of the Order Form, We will send a confirmation e-mail
              to the address indicated therein and will establish Your Master
              Account. You may begin use of the Service only after receiving
              such confirmation e-mail.
            </p>
            <p>
              You shall not permit any third party to use or gain access to
              Zbang and shall use all reasonable security measures to protect
              against such unauthorised usage and/or access. You are solely
              responsible for the use of Zbang by any of Your employees, agents
              or any other person, whether authorised or otherwise.
            </p>
            <p>
              We shall not access Your account for purposes other than in the
              course of general administration and maintenance or to provide
              support.
            </p>
            <h3>Intellectual Property</h3>
            <ul>
              <li>
                You shall not rent, lease, sublicense, distribute, transfer,
                copy or modify Zbang.
              </li>
              <li>
                You shall not adapt Zbang in any way or use it to create a
                derivative work.
              </li>
              <li>
                You shall not translate, decompile, or create or attempt to
                create, by way of reverse engineering or otherwise, the source
                code of Zbang.
              </li>
              <li>
                You shall not use Zbang in any manner, or in connection with any
                content, data, hardware, software or other materials, that:
                <ul>
                  <li>
                    infringes upon or violates any patent, copyright, trade
                    secret, trademark or other intellectual property right of
                    any third party.
                  </li>
                  <li>
                    constitutes a defamation, libel, invasion of privacy, or
                    violation of any right of publicity or other third party
                    right, or is threatening, harassing or malicious.
                  </li>
                  <li>
                    constitutes phishing, pharming or impersonates any other
                    person or entity, or steals or assumes any person’s
                    identity, whether a real identity or otherwise.
                  </li>
                  <li>
                    violates any applicable laws, ordinance, rule, regulation or
                    treaty.
                  </li>
                </ul>
              </li>
            </ul>
            <h3>4. Service</h3>
            <p>
              The Service is a SaaS advertising tools and documentation and it
              is intended to assist in the creation, management, monitoring and
              optimization of advertising campaigns on the applicable Ad
              Networks, but is not intended to replace customer’s discretion. In
              addition, marketing effectiveness is affected by a multitude of
              factors, such as such Ad Networks conduct and compliance, product
              quality and marketing strategies. Therefore, We do not warrant or
              guarantee any results of a campaign or from the use of the Service
              will meet Your expectations or requirements or produce profit. It
              is acknowledged by You that the Services are largely depended on
              the availability and operation of third-party platforms and
              websites, including, without limitation, the applicable Ad
              Networks and that Zbang shall not bear responsibility or liability
              with respect to such, their operation, rules, functionalities
              and/or changes made thereto.
            </p>
            <p>
              We are continuously working on adding improving the features of
              the Services, and may do so in its discretion, and as part of
              such, We may also remove certain function which are not frequently
              used or are replaced by other functions.
            </p>
            <p>
              You may not use the Service for any of the following purposes
              including without limitation managing campaigns promoting or
              supporting any of the following purposes (the "Prohibited
              Activities") and We disclaim any and all liabilities, losses and
              or damages with respect to any use of the Services which
              constitutes a Prohibited Activity: (a) Engaging in unlawful,
              indecent, obscene or other morally objectionable activities; (b)
              Disseminating any unlawful, harassing, abusive, threatening,
              harmful, vulgar, obscene or otherwise objectionable or illegal
              materials; (c) Transmitting material that encourages conduct which
              may constitute a criminal offence, result in civil liability or
              otherwise breaches any relevant law, regulation and/or code of
              practice; (d) Gaining unauthorized access to other computer
              systems; (e) Interfering with any other person's use of the
              Service and/or using the Services to provide services to third
              parties which are similar to the Services (or parts thereof); (f)
              Transmitting, junk mail, chain letters or "spam"; (g) Making
              and/or transferring and/or storing electronic copies of materials
              protected by copyright without the express permission of the owner
              of the material, or otherwise making such use of the Service that
              infringes third parties intellectual property rights; (h)
              Circumvent, disable or otherwise interfere with security related
              features or other features of the Platform and/or with the use of
              the Services, or enforce any limitations on use of the Platform
              and/or the Services; and (i) transmitting materials or otherwise
              acting (or failing to act) in breach of the terms and condition of
              any applicable Ad Network.
            </p>
            <h3>5. Third Party Services</h3>
            <p>
              Zbang through the Service may make available to software,
              applications and/or services provided and licensed by third
              parties ("Third Party Services"). The use of such Third Party
              Services in or with the Service is subject to the applicable
              licenses, terms of use and conditions of such Third Party
              Services. In the event that no such third party license agreements
              exist, the restrictions contained in this agreement shall apply on
              all such Third Party Services, mutatis mutandis. Zbang disclaims
              any warranty or representation with regards to any use of any of
              such Third Party Services, which use is on an as-is basis. In as
              much as the website, the Platform and/or the Services contain
              links to websites which are not operated by Zbang ("Third Party
              Websites"), Zbang shall not bear any responsibility or liability
              with regards to any such Third Party Websites, their contents, the
              information presented therein, etc., and any use thereof, and in
              no event will Zbang be liable in any way for any damages of any
              kind whatsoever resulting from or in connection with any use of
              any such Third Party Services and/or Third Party Websites or any
              reliance on any information presented therein, and any such use
              shall be on user’s own discretion, risk and responsibility.
            </p>
            <h3>6. Fee sand Payments; Other Commercial Terms</h3>
            <p>
              The subscription fee corresponds to the amount of Ad Spend
              associated with Your Master Account.
            </p>
            <p>
              To the extent actual Ad Spend exceeds the amount indicated in the
              Order Form, You may be subject to excess surcharge. Rates may be
              amended from time to time, such amendment shall not affect any Ad
              Accounts existed prior to change date.
            </p>
            <p>
              Our system automatically fits the optimum payment package to the
              actual Ad Spend use in order to minimize costs.
            </p>
            <p>
              Zbang will issue a monthly invoice for the applicable fees
              available to You in the dedicated section within the Account.
              Payment shall be made within 7 days as of receipt of the invoice.
              Due but unpaid amounts shall incur a late payment charge at a rate
              of 12% per annum, compounded annually, or the maximum amount
              permitted under applicable law, the lower of the two, commencing
              as of the due payment date and until finally paid in full.
            </p>
            <p>
              Zbang may offer or perform additional services to You which are
              not included in the purchased subscription and which may be
              subject to additional fees.
            </p>
            <p>
              Stated prices (indicated in the Order Form and/or on Zbang
              website) do not include sales tax or VAT.
            </p>
            <h3>7. Proprietary Rights</h3>
            <p>
              All algorithms, methods of computation and data processed by Zbang
              to measure account performance or any other data of statistical
              nature necessary for the proper functioning of Zbang, are and
              shall remain Our sole and exclusive property, including all
              applicable rights to patents, copyrights, trademarks, trade
              secrets, or other proprietary or intellectual property rights
              inherent therein or appurtenant thereto. We reserve all rights not
              expressly granted to You herein.
            </p>
            <p>
              Your materials (i.e. campaigns, adverts, imagery, etc.) are and
              shall remain Your sole and exclusive property, including all
              applicable rights to patents, copyrights, trademarks, trade
              secrets, or other proprietary or intellectual property rights
              inherent therein or appurtenant thereto.
            </p>
            <h3>8. Warranty;Support; Limitation of Liability</h3>
            <p>
              The Service (and the Platform), are provided on an "as is" and “as
              available” basis without any warranty whatsoever, whether
              expressed or implied. Although We employs reasonable commercial
              efforts, Zbang does not guarantee that use of the Service shall be
              uninterrupted or error free. It is acknowledged by You that Zbang
              may, upon prior notice, suspend or shutdown the Service for
              maintenance and updates purposes. Zbang does not assume any
              liability with respect to an interruption, failure, damage or
              liability caused from the use or non-use of any third- party
              platforms and websites, including, without limitation, an Ad
              Network, nor it provides any warranties or guarantees with respect
              to the foregoing.
            </p>
            <p>
              We shall not be obligated to provide You with any support, but we
              may elect to do so at Our sole discretion. In the event, technical
              support shall be provided in English based on the agreed plan
              chosen by You, and refers to the working days Monday through
              Friday, from 09:00 to 17:00 Greenwich Mean Time, or when in
              effect, British Summer Time.
            </p>
            <p>
              We do not warrant or guarantee any results of a campaign or from
              the use of the Service will meet Your expectations or requirements
              or produce profit. It is acknowledged by You that the Services are
              largely depended on the availability and operation of third-party
              platforms and websites, including, without limitation, the
              applicable Ad Networks and that Zbang shall not bear
              responsibility or liability with respect to such, their operation,
              rules, functionalities and/or changes made thereto.
            </p>
            <h3>9. Confidentiality</h3>
            <p>
              Information provided by Zbang or is included in the Service
              (except for customer generated content and except for reports and
              information on customer’s activities as presented in the
              Platform), shall be deemed as confidential information, and shall
              be kept in strict confidence. You may not disclose any such
              information unless they are compelled to do so pursuant to a valid
              court order, in which case, You shall notify Us, if permitted
              under applicable law, promptly upon receipt of such order.
            </p>
            <h3>10. Term</h3>
            <p>
              These Terms are effective as long as You are a registered user of
              Zbang. We will provide the services to You on a month-to-month
              basis.
            </p>
            <h3>11. Termination</h3>
            <ul>
              <li>
                Subscription of the Services may be terminated at any time by
                canceling the subscription from the Zbang Platform. Termination
                shall be effective upon receipt of such email. Any amounts paid
                in advance by You shall not be refunded.
              </li>
              <li>
                We may terminate as ubscription and/or close an AdAccount for
                any reason. In addition, We may suspend or deny access to the
                Service (and the Platform) or use thereof upon the occurrence of
                any of the following, with immediate effect:
              </li>
              <li>
                if You or an Additional user assigned thereto commits a material
                breach of any of its undertakings, warranties and
                representations hereunder, and such breach is not remedied
                within ten (10) days following written notice with respect to
                the breach; upon
              </li>
              <li>
                any proceedings are commenced by, for, or against You under any
                bankruptcy, insolvency, or debtor’s relief law for the purpose
                of seeking reorganization of customer’s debts, and such
                proceeding is not dismissed with fifteen (15) calendar days of
                its commencement;
              </li>
              <li>
                if a customer breaches the terms and conditions relating to an
                Ad Network or the Zbang is requested by an Ad Network in writing
                to refrain from providing services to the customer. Such
                termination shall be without prejudice to any other right.
              </li>
              <li>
                Upon termination and/or expiration of the Term for any reason,
                You and any Additional Users appointed by it or any of their
                clients shall no longer be allowed to use or access the Service
                (or the Platform).
              </li>
            </ul>
            <h3>12. Modifications</h3>
            <ul>
              <li>
                Wemay,from time to time, modify the Terms or billing plans.We
                will post notice of modifications hereto at Our website. Changes
                will not apply retroactively and will become effective
                immediately after they are posted. Modifications to billing
                plans will come into force at the beginning of the next billing
                period.
              </li>
              <li>
                All amendments or modifications will be binding if You continue
                to use Zbang after modifications to these Terms come into force.
              </li>
            </ul>
            <h3>13. General</h3>
            <ul>
              <li>
                All agreements between you and Us shall be governed by, and
                construed in accordance with, the laws of England and Wales.
              </li>
              <li>
                Any litigation based hereon, or arising out of, under, or in
                connection with these Terms(and all agreements between you and
                Us), shall be brought and maintained exclusively in the
                competent courts in England and Wales.{" "}
              </li>
              <li>
                Neither Party shall be deemed in default of these Terms to the
                extent that performance of its obligations or attempts to cure
                any breach are delayed or prevented by reason of any act of God,
                fire, natural disaster, accident, terrorism, riot, act of
                government, shortage of materials or supplies, or any other
                cause beyond the reasonable control of such Party.{" "}
              </li>
              <li>
                Please see Our separate Privacy Policy in relation to how We
                collect and use Your information, which will become binding on
                you as at the date You register as a user of Zbang.{" "}
              </li>
              <li>
                Any complaints that arise from these Terms or use of Zbang can
                be directed to go@zbang.ai. All complaints will be handled
                within a reasonable timeframe.{" "}
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Terms;
