import React, { useContext } from "react";
import { AuthContext } from "../config/AuthProvider";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import PublicTwoToneIcon from "@material-ui/icons/PublicTwoTone";
import { withRouter } from "react-router-dom";
import firebase from "../config/firebase";
import Button from "@material-ui/core/Button";

dayjs.extend(utc);
dayjs.extend(timezone);

const AdAccountTimezone = (props) => {
  const { profile } = useContext(AuthContext);

  const handleAdAccountClick = () => {
    props.history.push("/settings", {
      highlight: "ad-account",
    });
  };

  if (
    profile &&
    profile.selected_ad_account &&
    profile.selected_ad_account.timezone
  ) {
    return (
      <div className="card">
        <div className="card-body">
          <React.Fragment>
            <div>
              <div className="ad-account-timezone">
                <PublicTwoToneIcon />
                <div className="date">
                  {dayjs()
                    .tz(profile.selected_ad_account.timezone)
                    .format("ddd, MMM D, YYYY")}
                </div>
                <div className="timezone">
                  {profile.selected_ad_account.timezone}
                  <sup>*</sup>
                </div>
                <div className="info">
                  *based on the timezone of your selected Ad Account{" "}
                  <span onClick={handleAdAccountClick}>
                    ({profile.selected_ad_account.name})
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <React.Fragment>
          <div>
            <div className="ad-account-timezone">
              <PublicTwoToneIcon />
              <div className="date">{dayjs().format("ddd, MMM D, YYYY")}</div>
              <div className="timezone">
                {dayjs.tz.guess()}
                <sup>*</sup>
              </div>
              <div className="info">
                * based on your system time settings. Select an Ad Account to
                see specific date information.
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default withRouter(AdAccountTimezone);
