import React, { useState, useRef, useEffect } from "react";
import firebase from "../config/firebase";
import { withRouter } from "react-router-dom";
import Loading from "../components/Loading";
import Login from "../pages/Login";

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const { children, history } = props;
  const [currentUser, setCurrentUser] = useState({
    is_loading: true,
    is_empty: true,
  });
  const [profile, setProfile] = useState({ is_loading: true, is_empty: true });
  const [isPublic, setIsPublic] = useState(false);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      props.location.pathname === "/cookies" ||
      props.location.pathname === "/privacy-policy" ||
      props.location.pathname === "/terms-and-conditions"
    ) {
      if (componentIsMounted.current) {
        setIsPublic(true);
      }
    }
  }, []);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      let tempUser = user;
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;
        tempUser.claims = claims;
        tempUser.is_loading = false;
        tempUser.is_empty = false;
        if (componentIsMounted.current) {
          setCurrentUser(tempUser);
        }
      } else {
        if (componentIsMounted.current) {
          setCurrentUser({ is_loading: false, is_empty: true });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!currentUser.is_loading && !currentUser.is_empty) {
      const userRef = firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid);
      userRef.onSnapshot((doc) => {
        if (doc.exists) {
          if (componentIsMounted.current) {
            setProfile(doc.data());
          }
        } else {
          if (componentIsMounted.current) {
            setProfile({ is_loading: false, is_empty: true });
          }
        }
      });
    }
    if (!currentUser.is_loading && currentUser.is_empty) {
      if (componentIsMounted.current) {
        setProfile({ is_loading: false, is_empty: true });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!profile.is_loading && profile.is_empty && !isPublic) {
      history.push("/", {
        notification: {
          message: "You are signed out! Please login!",
          severity: "warning",
          timeout: 12000,
          status: true,
        },
      });
    }
    // eslint-disable-next-line
  }, [profile]);

  if (currentUser.is_loading || profile.is_loading) {
    return <Loading />;
  }

  if (
    !currentUser.is_loading &&
    !currentUser.is_empty &&
    !profile.is_loading &&
    !profile.is_empty
  ) {
    return (
      <AuthContext.Provider
        value={{
          currentUser,
          profile,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  if (isPublic) {
    return <div>{children}</div>;
  }

  return <Login />;
};

export default withRouter(AuthProvider);
