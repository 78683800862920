import React from "react";

const CheckmarkIcon = ({ className }) => {
  return (
    <div className="checkmark">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polyline
          fill="none"
          style={{ stroke: "currentColor" }}
          strokeWidth="1.1"
          points="4,10 8,15 17,4"
        ></polyline>
      </svg>
    </div>
  );
};

export default CheckmarkIcon;
