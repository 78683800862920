import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import facebook from "../images/bflogo.png";
import Alert from "@material-ui/lab/Alert";
import firebase from "../config/firebase";
import { withRouter, Link } from "react-router-dom";
import "../css/login.css";
import logo from "../images/logo.svg";
import background from "../images/login.jpeg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { AuthContext } from "../config/AuthProvider";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import CheckIcon from "@material-ui/icons/Check";

const ConnectFacebook = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "",
    timeout: 12000,
    open: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  let provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope("public_profile");
  provider.addScope("email");
  provider.addScope("ads_management");
  provider.addScope("business_management");
  provider.addScope("ads_read");
  provider.addScope("catalog_management");
  provider.addScope("attribution_read");
  provider.addScope("pages_show_list");
  provider.addScope("pages_read_engagement");
  provider.setCustomParameters({
    display: "popup",
  });

  const functions = firebase.functions();

  const handleClose = () => {
    if (componentIsMounted.current) {
      setUserMessage({ message: "", severity: "", open: false });
    }
  };

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleFacebookLogin = async (event) => {
    event.preventDefault();
    const firestore = firebase.firestore();

    let credential, access_token, user, profile, info;

    if (componentIsMounted.current) {
      setIsLoading(true);
    }

    try {
      const result = await firebase.auth().currentUser.linkWithPopup(provider);
      credential = result.credential;
      access_token = credential.accessToken;
      user = result.user;
      info = result.additionalUserInfo;
      profile = result.additionalUserInfo.profile;

      await firestore
        .collection("users")
        .doc(user.uid)
        .set(
          {
            facebook_email: profile.email ? profile.email : null,
            first_name: profile.first_name,
            last_name: profile.last_name,
            full_name: profile.name,
            fbuid: profile.id,
            picture: profile.picture.data,
            granted_scopes: profile.granted_scopes,
            is_facebook_connected: true,
            is_loading: false,
            is_empty: false,
          },
          { merge: true }
        );

      await firestore.collection("tokens").doc(user.uid).set({
        access_token: access_token,
        fbuid: profile.id,
      });

      // The user is new everytime because facebook will be connected here only once after email and password signup
      let request = functions.httpsCallable("get_businesses");
      await request();
      props.history.push("/dashboard", {
        notification: {
          message: `Your are now signed in as ${profile.name}!`,
          severity: "success",
          timeout: 12000,
          status: true,
        },
      });
    } catch (error) {
      if (componentIsMounted.current) {
        setUserMessage({
          message: error.message,
          severity: "error",
          timeout: 12000,
          open: true,
        });
        setIsLoading(false);
      }
      await firestore.collection("errors").add({
        performed_action: "profile_update_on_facebook_connect",
        performed_at: new Date(),
        error: error.toString(),
      });
    }
  };

  const handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {})
      .catch((error) => {
        setUserMessage({
          message: error.message,
          severity: "error",
          timeout: 12000,
          open: true,
        });
      });
  };
  return (
    <React.Fragment>
      <div className="login">
        <div
          className="content"
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <div className="login-form">
            <div className="form-content">
              {!profile.is_facebook_connected ? (
                <img src={logo} alt="Zbang" className="logo" />
              ) : (
                <Avatar
                  alt={profile.full_name}
                  src={profile.picture.url}
                  className="facebook-avatar"
                />
              )}
              {!profile.is_facebook_connected ? (
                <React.Fragment>
                  <h1 className="title">...one more step</h1>
                  <p className="paragraph">Connect your Facebook account</p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h1 className="title">
                    <span className="connected-checkmark">
                      <CheckIcon />
                    </span>
                    Connected
                  </h1>
                  <p className="paragraph">
                    You are connected as {profile.full_name}
                  </p>
                </React.Fragment>
              )}

              {!profile.is_facebook_connected ? (
                <div className="sign-in-methods">
                  <React.Fragment>
                    <Button
                      className="login-button login-facebook"
                      type="button"
                      onClick={handleFacebookLogin}
                      endIcon={
                        isLoading ? <CircularProgress size={18} /> : null
                      }
                    >
                      <img
                        src={facebook}
                        alt="Facebook"
                        width={30}
                        height={30}
                      />
                      Connect Facebook
                    </Button>
                  </React.Fragment>
                </div>
              ) : null}
              {!profile.is_facebook_connected ? (
                <Button onClick={handleSignout}>Sign out</Button>
              ) : (
                <Button
                  onClick={() => {
                    props.history.push("/dashboard");
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              )}
            </div>
            {!profile.is_facebook_connected ? (
              <div className="legal">
                <p>
                  By continuing, you are indicating that you accept our{" "}
                  <Link to="/terms-of-service">Terms of Service</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Snackbar
        open={userMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default withRouter(ConnectFacebook);
