import React from "react";
import Tippy from "@tippyjs/react/headless";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import Button from "@material-ui/core/Button";
import { useSpring, motion } from "framer-motion";
import styled from "styled-components";

const Box = styled(motion.div)``;

const Info = (props) => {
  const { title, html } = props;
  const springConfig = { damping: 15, stiffness: 300 };
  const initialScale = 0.5;
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(initialScale, springConfig);

  const onMount = () => {
    scale.set(1);
    opacity.set(1);
  };

  const onHide = ({ unmount }) => {
    const cleanup = scale.onChange((value) => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });

    scale.set(initialScale);
    opacity.set(0);
  };
  return (
    <Tippy
      render={(attrs) => (
        <Box
          className="info-box"
          tabIndex="-1"
          style={{ scale, opacity }}
          {...attrs}
        >
          {html}
        </Box>
      )}
      animation={true}
      onMount={onMount}
      onHide={onHide}
      interactive={true}
      hideOnClick={true}
      trigger={"mouseenter focus"}
    >
      <Button className="info-box-trigger">
        <InfoTwoToneIcon fontSize="small" />
      </Button>
    </Tippy>
  );
};

export default Info;
