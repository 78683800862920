import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./css/normalize.css";
import "./css/style.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "./config/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import AuthProvider from "./config/AuthProvider";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import PasswordReset from "./pages/PasswordReset";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Authenticated from "./pages/Authenticated";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51Idr1gBykxROM0pXO910nNqHglPm5nEouyiDXOiwrkvruXZ2rYaliAMkqBnBKoO820xCM3QhWQaYNp4ONQCMdAtI009zm0GsfU"
);

//routes
//terms-of-service
//privacy-policy
//sign-up
//password-reset

const App = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        letterSpacing: "2px",
        wordSpacing: "5px",
      }}
    >
      UNDER CONSTRUCTION
    </div>
  );
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/sign-in" exact component={Login} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/password-reset" exact component={PasswordReset} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/terms-of-service" exact component={Terms} />
          <AuthProvider>
            <Elements stripe={stripePromise}>
              <Authenticated />
            </Elements>
          </AuthProvider>
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
