import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Button from "@material-ui/core/Button";
import { numberWithCommas } from "../config/utils";
import { CircularProgress } from "@material-ui/core";
import firebase from "../config/firebase";

const ReportMetrics = (props) => {
  const {
    classes,
    setReportSelectedDialogOpen,
    metrics,
    isLoading,
    profile,
  } = props;
  const [compare, setCompare] = useState(null);

  const handleSeeMore = () => {
    setReportSelectedDialogOpen(true);
    const firestore = firebase.firestore();

    firestore
      .collection("reports")
      .doc(profile.uid)
      .set(
        {
          selected_product: null,
          uid: profile.uid,
        },
        { merge: true }
      )
      .then(() => {})
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    if (metrics) {
      setCompare({
        purchase_roas: metrics.previous
          ? parseFloat(metrics.current.purchase_roas) -
            parseFloat(metrics.previous.purchase_roas)
          : "-",
        spend: metrics.previous
          ? parseInt(metrics.current.spend) - parseInt(metrics.previous.spend)
          : "-",
        purchases: metrics.previous
          ? parseInt(metrics.current.purchases) -
            parseInt(metrics.previous.purchases)
          : "-",
        impressions: metrics.previous
          ? parseInt(metrics.current.impressions) -
            parseInt(metrics.previous.impressions)
          : "-",
      });
    }
  }, [props]);

  console.log("isloading?", metrics);

  if (metrics && metrics.current) {
    return (
      <div className={`report-metrics ${classes}`}>
        <div className="metric">
          <div className="title">ROAS</div>
          <div className="value current">{metrics.current.purchase_roas}x</div>
          <div className="value previous">
            {compare ? (
              compare.purchase_roas === "-" ? (
                <span className="difference neutral">
                  {compare.purchase_roas}
                </span>
              ) : compare.purchase_roas > 0 ? (
                <span className="difference positive">
                  <ArrowUpwardIcon /> +{compare.purchase_roas.toFixed(2)}
                </span>
              ) : compare.purchase_roas === 0 ? (
                <span className="difference neutral">0</span>
              ) : (
                <span className="difference negative">
                  <ArrowDownwardIcon /> {compare.purchase_roas.toFixed(2)}
                </span>
              )
            ) : null}
          </div>
        </div>
        <div className="metric">
          <div className="title">
            SPEND
            <span className="currency">{metrics.current.account_currency}</span>
          </div>
          <div className="value current">
            {numberWithCommas(metrics.current.spend)}
          </div>
          <div className="value previous">
            {compare ? (
              compare.spend === "-" ? (
                <span className="difference neutral">{compare.spend}</span>
              ) : compare.spend > 0 ? (
                <span className="difference positive">
                  <ArrowUpwardIcon /> +
                  {numberWithCommas(compare.spend.toFixed(2))}
                </span>
              ) : compare.spend === 0 ? (
                <span className="difference neutral">0</span>
              ) : (
                <span className="difference negative">
                  <ArrowDownwardIcon />{" "}
                  {numberWithCommas(compare.spend.toFixed(2))}
                </span>
              )
            ) : null}
          </div>
        </div>
        {isLoading ? (
          <div className="asset-is-loading">
            <CircularProgress />
          </div>
        ) : null}
        <div className="metric">
          <div className="title">PURCHASES</div>
          <div className="value current">
            {numberWithCommas(metrics.current.purchases)}
          </div>
          <div className="value previous">
            {compare ? (
              compare.purchases === "-" ? (
                <span className="difference neutral">{compare.purchases}</span>
              ) : compare.purchases > 0 ? (
                <span className="difference positive">
                  <ArrowUpwardIcon /> +{numberWithCommas(compare.purchases)}
                </span>
              ) : compare.purchases === 0 ? (
                <span className="difference neutral">0</span>
              ) : (
                <span className="difference negative">
                  <ArrowDownwardIcon /> {numberWithCommas(compare.purchases)}
                </span>
              )
            ) : null}
          </div>
        </div>
        <div className="metric">
          <div className="title">IMPRESSIONS</div>
          <div className="value current">
            {numberWithCommas(metrics.current.impressions)}
          </div>
          <div className="value previous">
            {compare ? (
              compare.purchases === "-" ? (
                <span className="difference neutral">
                  {compare.impressions}
                </span>
              ) : compare.impressions > 0 ? (
                <span className="difference positive">
                  <ArrowUpwardIcon /> +{numberWithCommas(compare.impressions)}
                </span>
              ) : compare.impressions === 0 ? (
                <span className="difference neutral">0</span>
              ) : (
                <span className="difference negative">
                  <ArrowDownwardIcon /> {numberWithCommas(compare.impressions)}
                </span>
              )
            ) : null}
          </div>
        </div>
        {setReportSelectedDialogOpen ? (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSeeMore}
          >
            See More
          </Button>
        ) : null}
      </div>
    );
  }
  return null;
};

export default ReportMetrics;
