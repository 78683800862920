import React, { useState, useEffect, PureComponent } from "react";
import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Bar,
  ComposedChart,
} from "recharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  date_preset_length,
  date_preset_name,
  numberWithCommas,
  kFormatter,
} from "../config/utils";

const Chart = (props) => {
  const { data, date_preset, color, title, isLoading, isMobile } = props;
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip">
          {title === "Impressions" ? (
            <p className="label">{`${numberWithCommas(
              parseInt(payload[0].payload.value)
            )} ${payload[0].payload.unit}`}</p>
          ) : null}
          {title === "Spend" ? (
            <p className="label">{`${numberWithCommas(
              payload[0].payload.value
            )} ${payload[0].payload.unit}`}</p>
          ) : null}
          {title !== "Impressions" && title !== "Spend" ? (
            <p className="label">{`${payload[0].payload.value}${payload[0].payload.unit}`}</p>
          ) : null}
        </div>
      );
    }

    return null;
  };

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={10}
            textAnchor="end"
            fill="#666"
            transform="rotate(-35)"
            fontSize={10}
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }

  class CustomizedYAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={10}
            textAnchor="end"
            fill="#666"
            transform="rotate(0)"
            fontSize={10}
          >
            {kFormatter(payload.value)}
          </text>
        </g>
      );
    }
  }

  const handleBarClick = (bar) => {
    //check if the click was on an actual value
    if (bar) {
      setSelected(bar.activePayload[0]);
    }
  };
  return (
    <React.Fragment>
      <div className="chart">
        <h4 className="chart-title">
          {title}

          <span>
            {isLoading ? <CircularProgress size={18} /> : null}
            <span className="date_preset">{date_preset_name(date_preset)}</span>
          </span>
        </h4>

        <div>
          <ResponsiveContainer width="99%" aspect={isMobile ? 2 : 3.3}>
            <ComposedChart
              width={500}
              height={500}
              data={data}
              margin={{
                top: isMobile ? 10 : 20,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              onClick={handleBarClick}
            >
              <defs>
                <linearGradient
                  id={`color-${title}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="10%" stopColor={color} stopOpacity={0.9} />
                  <stop offset="95%" stopColor={color} stopOpacity={0} />
                </linearGradient>
              </defs>
              <YAxis
                domain={[0, "max"]}
                hide={false}
                tick={<CustomizedYAxisTick />}
                width={38}
              />
              <XAxis
                dataKey={
                  date_preset_length(date_preset) > 90
                    ? "month_name"
                    : date_preset_length(date_preset) > 7
                    ? "ordinal"
                    : "day_name"
                }
                //interval={0}
                tick={<CustomizedAxisTick />}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="max" fill="#1c1a24" />
              <Area
                type="monotone"
                dataKey={isLoading ? null : "value"}
                stroke={color}
                fill="transparent"
                fill={`url(#color-${title})`}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        {selected && selected.payload ? (
          <div className="chart-details">
            <h3 style={{ color: color }}>
              {title}: {numberWithCommas(selected.payload.value)}
              {selected.payload.unit}
            </h3>
            <div className="date">
              {date_preset_length(date_preset) > 90
                ? selected.payload.month_year
                : selected.payload.full_date && selected.payload.full_date}
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Chart;
