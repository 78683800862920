import React, { useState, useEffect, useRef } from "react";
import ReportItem from "./ReportItem";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import firebase from "../config/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";

const ReportSidebar = (props) => {
  const {
    products,
    paging,
    profile,
    isMobile,
    isLoading,
    handleProductSelect,
    preset,
    selected,
    handleProductRemove,
    handleProductAdd,
    removed,
    productToEdit,
    setProductToEdit,
    report,
  } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [scopedIsLoading, setScopedIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "success",
    timeout: 12000,
    open: false,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setUserMessage({ message: "", severity: "", open: false });
  };

  const handleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleProductToEdit = (product_id) => {
    setProductToEdit(product_id);
  };

  const handlePagination = async () => {
    if (paging && paging.cursors && paging.cursors.after) {
      if (componentIsMounted.current) {
        setScopedIsLoading(true);
      }
      const functions = firebase.functions();
      const request = functions.httpsCallable("get_report_products");

      try {
        await firebase
          .firestore()
          .collection("reports")
          .doc(profile.uid)
          .update({
            is_products_loading: true,
            updated_at: new Date(),
            uid: profile.uid,
          });
        const result = await request({
          object:
            report && report.object
              ? { ...report.object }
              : {
                  ...profile.selected_ad_account,
                  id: profile.selected_ad_account.account_id,
                  type: "ad_account",
                  edge: profile.selected_ad_account.id,
                },
          date_preset: preset,
          after: paging.cursors.after,
        });

        if (componentIsMounted.current) {
          setUserMessage({
            message:
              result.data.length % 10 === 0
                ? `20 new products added!`
                : `${result.data.length % 20} new products added!`,
            severity: "success",
            timeout: 5000,
            open: true,
          });
          setScopedIsLoading(false);
        }
      } catch (error) {
        if (componentIsMounted.current) {
          setUserMessage({
            message: error.toString(),
            severity: "error",
            timeout: 5000,
            open: true,
          });
          setScopedIsLoading(false);
        }
      }
    }
  };

  return (
    <div className="sidebar">
      {!isMobile ? <Toolbar className="fake-toolbar" /> : null}
      <div className="sidebar-header">
        <div className="title-action">
          <h3 className="title">
            Products {isLoading ? <CircularProgress size={18} /> : null}
          </h3>
          <Button variant="text" color="primary" onClick={handleEditMode}>
            {isEditMode ? "Done" : "Edit"}
          </Button>
        </div>
        <div className="table-head">
          <span>Impressions/ROAS</span>
        </div>
      </div>
      <ul className={isLoading ? "loading" : null}>
        {products.length === 0 ? (
          <li className="item no-products-to-show">
            <span>There are no products to show</span>
          </li>
        ) : null}
        {products.map((product, index) => {
          return (
            <ReportItem
              product={product}
              productToEdit={productToEdit}
              index={index}
              isSelected={selected === product.id}
              isEditMode={isEditMode}
              removed={
                _.find(removed, function (o) {
                  return o.product_id === product.id && o.type === "manual";
                }) || null
              }
              automaticallyRemoved={
                _.find(removed, function (o) {
                  return o.product_id === product.id && o.type === "auto";
                }) || null
              }
              handleProductToEdit={handleProductToEdit}
              handleProductSelect={handleProductSelect}
              key={product.id}
              isLoading={isLoading}
              handleProductRemove={handleProductRemove}
              handleProductAdd={handleProductAdd}
              profile={profile}
            />
          );
        })}
        <li className="item actions">
          {paging &&
          paging.cursors &&
          paging.cursors.after &&
          products.length % 10 === 0 ? (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handlePagination}
              disabled={scopedIsLoading}
            >
              Load more
              {scopedIsLoading ? <CircularProgress size={18} /> : null}
            </Button>
          ) : products.length > 0 ? (
            <span className="end-reached">You reached the end</span>
          ) : null}
        </li>
      </ul>
      <Snackbar
        open={userMessage.open}
        autoHideDuration={userMessage.timeout}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReportSidebar;
