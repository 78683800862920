import React, { useState, useEffect, useContext, useRef } from "react";

import { AuthContext } from "../config/AuthProvider";
import { Redirect } from "react-router-dom";
import Menu from "../components/Menu";
import Notification from "../components/Notification";
import Bar from "../components/Bar";
import Page from "../components/Page";
import Button from "@material-ui/core/Button";

import SelectBusiness from "../components/SelectBusiness";
import SelectAdAccount from "../components/SelectAdAccount";
import firebase from "../config/firebase";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ReportSidebar from "../components/ReportSidebar";
import ReportMetrics from "../components/ReportMetrics";
import ReportSelectedAdAccount from "../components/ReportSelectedAdAccount";
import ReportSelectedProduct from "../components/ReportSelectedProduct";
import DatePreset from "../components/DatePreset";
import ReportCharts from "../components/ReportCharts";
import ReportSelectedMobile from "../components/ReportSelectedMobile";
import BottomNavigationMobile from "../components/BottomNavigationMobile";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CreateCampaignModal from "../components/CreateCampaignModal";
import ReportSelectedCampaign from "../components/ReportSelectedCampaign";

const Dashboard = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const [reportSelectedDialogOpen, setReportSelectedDialogOpen] = useState(
    false
  );
  const [report, setReport] = useState({
    is_loading: true,
    is_empty: true,
  });
  const [products, setProducts] = useState([]);
  const [preset, setPreset] = useState("");
  const [chartData, setChartData] = useState([]);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "success",
    timeout: 12000,
    open: false,
  });
  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState(false);

  const [productToEdit, setProductToEdit] = useState("");

  const handleClose = () => {
    setUserMessage({ message: "", severity: "", open: false });
  };

  const handlePresetChange = async (value) => {
    setPreset(value);
    const functions = firebase.functions();
    const get_report_products = functions.httpsCallable("get_report_products");
    const get_report_metrics = functions.httpsCallable("get_report_metrics");
    const get_report_charts = functions.httpsCallable("get_report_charts");

    const get_selected_product = functions.httpsCallable(
      "get_selected_product"
    );

    if (report.selected_product) {
      setReport({
        ...report,
        is_metrics_loading: true,
        is_charts_loading: true,
        cache: [],
        selected_product: {
          ...report.selected_product,
          date_preset: value,
        },
      });
      try {
        await firebase
          .firestore()
          .collection("reports")
          .doc(profile.uid)
          .set(
            {
              is_metrics_loading: true,
              is_charts_loading: true,
              cache: [],
              selected_product: {
                ...report.selected_product,
                date_preset: value,
              },
              updated_at: new Date(),
              uid: profile.uid,
            },
            { merge: true }
          );
        await get_selected_product({
          object: {
            ...report.object,
          },
          product_id: report.selected_product.id,
          date_preset: value,
        });
      } catch (error) {
        console.log(error.toString());
      }
    } else {
      setReport({
        ...report,
        is_metrics_loading: true,
        is_charts_loading: true,
        is_products_loading: true,
        date_preset: value,
        cache: [],
        selected_product: null,
      });
      try {
        await firebase.firestore().collection("reports").doc(profile.uid).set(
          {
            is_metrics_loading: true,
            is_charts_loading: true,
            is_products_loading: true,
            date_preset: value,
            cache: [],
            selected_product: null,
            updated_at: new Date(),
            uid: profile.uid,
          },
          { merge: true }
        );
        await get_report_products({
          object:
            report && report.object
              ? { ...report.object }
              : {
                  ...profile.selected_ad_account,
                  id: profile.selected_ad_account.account_id,
                  type: "ad_account",
                  edge: profile.selected_ad_account.id,
                },
          date_preset: value,
          after: null,
        });
        await get_report_metrics({
          object:
            report && report.object
              ? { ...report.object }
              : {
                  ...profile.selected_ad_account,
                  id: profile.selected_ad_account.account_id,
                  type: "ad_account",
                  edge: profile.selected_ad_account.id,
                },
          date_preset: value,
        });
        await get_report_charts({
          object:
            report && report.object
              ? { ...report.object }
              : {
                  ...profile.selected_ad_account,
                  id: profile.selected_ad_account.account_id,
                  type: "ad_account",
                  edge: profile.selected_ad_account.id,
                },
          date_preset: value,
        });
      } catch (error) {
        console.log(error.toString());
      }
    }
  };

  const handleProductRemove = async (product) => {
    console.log("Product", product);

    const roas = product.current.purchase_roas;
    const impressions = product.current.impressions;
    const product_id = product.id;
    const firestore = firebase.firestore();
    const functions = firebase.functions();
    const date = firebase.firestore.Timestamp.fromDate(new Date());
    if (report.object && report.object.type === "campaign") {
      const update_campaign_removed_products = functions.httpsCallable(
        "update_campaign_removed_products"
      );
      try {
        await update_campaign_removed_products({
          campaign_id: report.object.id,
          product_set_id: report.object.product_set.id,
          removed_products: _.uniq([
            ...report.object.product_set.removed_products,
            {
              purchase_roas: roas,
              impressions: impressions,
              product_id: product_id,
              removed_at: date,
              type: "manual",
              rule: "",
            },
          ]),
        });
        setProductToEdit("");
        setUserMessage({
          message: "Selected product has been removed!",
          severity: "success",
          timeout: 5000,
          open: true,
        });
      } catch (error) {
        setUserMessage({
          message: error.toString(),
          severity: "error",
          timeout: 5000,
          open: true,
        });
      }
    }
    if (report.object && report.object.type === "ad_account") {
      try {
        await firestore
          .collection("reports")
          .doc(profile.uid)
          .set(
            {
              removed_products: report.removed_products
                ? _.uniq([
                    ...report.removed_products,
                    {
                      purchase_roas: roas,
                      impressions: impressions,
                      product_id: product_id,
                      removed_at: date,
                      type: "manual",
                      rule: "",
                    },
                  ])
                : [
                    {
                      purchase_roas: roas,
                      impressions: impressions,
                      product_id: product_id,
                      removed_at: date,
                      type: "manual",
                      rule: "",
                    },
                  ],
              uid: profile.uid,
            },
            { merge: true }
          );
        setProductToEdit("");
        setUserMessage({
          message: "Selected product has been removed!",
          severity: "success",
          timeout: 5000,
          open: true,
        });
      } catch (error) {
        setUserMessage({
          message: error.toString(),
          severity: "error",
          timeout: 5000,
          open: true,
        });
      }
    }
  };

  const handleProductAdd = async (product_id) => {
    const firestore = firebase.firestore();
    const functions = firebase.functions();
    if (report.object && report.object.type === "campaign") {
      const removed_products = report.object.product_set.removed_products
        ? report.object.product_set.removed_products
        : [];
      if (removed_products.length > 0) {
        var new_removed_products = _.remove(removed_products, function (n) {
          return n.product_id === product_id;
        });
        const update_campaign_removed_products = functions.httpsCallable(
          "update_campaign_removed_products"
        );
        try {
          await update_campaign_removed_products({
            campaign_id: report.object.id,
            product_set_id: report.object.product_set.id,
            removed_products: [...removed_products],
          });
          setProductToEdit("");
          setUserMessage({
            message: "Selected product has been added!",
            severity: "success",
            timeout: 5000,
            open: true,
          });
        } catch (error) {
          setUserMessage({
            message: error.toString(),
            severity: "error",
            timeout: 5000,
            open: true,
          });
        }
      } else {
        setUserMessage({
          message: "The product was never removed!",
          severity: "warning",
          timeout: 5000,
          open: true,
        });
      }
    }
    if (report.object && report.object.type === "ad_account") {
      const removed_products = report.removed_products
        ? report.removed_products
        : [];
      if (removed_products.length > 0) {
        var new_removed_products = _.remove(removed_products, function (n) {
          return n.product_id === product_id;
        });
        try {
          await firestore
            .collection("reports")
            .doc(profile.uid)
            .set(
              {
                removed_products: [...removed_products],
                uid: profile.uid,
              },
              { merge: true }
            );

          setProductToEdit("");
          setUserMessage({
            message: "Selected product has been added!",
            severity: "success",
            timeout: 5000,
            open: true,
          });
        } catch (error) {
          setUserMessage({
            message: error.toString(),
            severity: "error",
            timeout: 5000,
            open: true,
          });
        }
      } else {
        setUserMessage({
          message: "The product was never removed!",
          severity: "warning",
          timeout: 5000,
          open: true,
        });
      }
    }
  };

  const handleProductSelect = async (product_id) => {
    const functions = firebase.functions();
    const currentProducts = report && report.products ? report.products : [];
    const cache = report && report.cache ? report.cache : [];
    let cached_product = _.find(cache, function (o) {
      return o.id === product_id;
    });
    let future_product = _.find(currentProducts, function (o) {
      return o.id === product_id;
    });
    if (cached_product) {
      try {
        await firebase
          .firestore()
          .collection("reports")
          .doc(profile.uid)
          .set(
            {
              selected_product: { ...cached_product },
              updated_at: new Date(),
              uid: profile.uid,
            },
            { merge: true }
          );

        setReportSelectedDialogOpen(true);
      } catch (error) {
        console.log(error.toString());
      }
    } else {
      const get_selected_product = functions.httpsCallable(
        "get_selected_product"
      );

      try {
        // await firebase
        //   .firestore()
        //   .collection("reports")
        //   .doc(profile.uid)
        //   .set(
        //     {
        //       is_charts_loading: true,
        //       selected_product: { ...future_product, chart: [] },
        //       updated_at: new Date(),
        //       uid: profile.uid,
        //     },
        //     { merge: true }
        //   );
        setReport({
          ...report,
          is_charts_loading: true,
          selected_product: { ...future_product, chart: [] },
        });
        setReportSelectedDialogOpen(true);
        await get_selected_product({
          object:
            report && report.object
              ? { ...report.object }
              : {
                  ...profile.selected_ad_account,
                  id: profile.selected_ad_account.account_id,
                  type: "ad_account",
                  edge: profile.selected_ad_account.id,
                },
          product_id: product_id,
          date_preset: preset,
        });
      } catch (error) {
        console.log(error.toString());
      }
    }
  };

  const isMobile = useMediaQuery("(max-width:1099px)");

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  const handleAdAccountSelect = () => {
    setReport({ ...report, selected_product: null });
    const firestore = firebase.firestore();

    firestore
      .collection("reports")
      .doc(profile.uid)
      .update({ selected_product: null })
      .then(() => {
        console.log("ok");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.create_campaign
    ) {
      setCreateCampaignModalOpen(props.location.state.create_campaign);
    }
  }, [props]);

  useEffect(() => {
    const reportRef = firebase
      .firestore()
      .collection("reports")
      .doc(profile.uid);
    reportRef.onSnapshot(async (doc) => {
      if (doc.exists) {
        if (componentIsMounted.current) {
          setReport({ ...doc.data(), is_loading: false, is_empty: false });
        }
      } else {
        if (componentIsMounted.current) {
          console.log("Thsi is empty");
          setReport({ is_loading: false, is_empty: true });
        }
      }
    });
  }, []);

  useEffect(async () => {
    console.log("Should Refresh Report", report);
    if (
      (profile &&
        profile.selected_ad_account &&
        !report.is_loading &&
        report.is_empty) ||
      (profile && profile.selected_ad_account && !report)
    ) {
      const functions = firebase.functions();
      const get_report_products = functions.httpsCallable(
        "get_report_products"
      );
      const get_report_metrics = functions.httpsCallable("get_report_metrics");
      const get_report_charts = functions.httpsCallable("get_report_charts");
      try {
        await firebase.firestore().collection("reports").doc(profile.uid).set(
          {
            is_metrics_loading: true,
            is_charts_loading: true,
            is_products_loading: true,
            date_preset: "last_30d",
            cache: [],
            selected_product: null,
            updated_at: new Date(),
            uid: profile.uid,
          },
          { merge: true }
        );
        await get_report_products({
          object: {
            ...profile.selected_ad_account,
            id: profile.selected_ad_account.account_id,
            type: "ad_account",
            edge: profile.selected_ad_account.id,
          },
          date_preset: "last_30d",
          after: null,
        });
        await get_report_metrics({
          object: {
            ...profile.selected_ad_account,
            id: profile.selected_ad_account.account_id,
            type: "ad_account",
            edge: profile.selected_ad_account.id,
          },
          date_preset: "last_30d",
        });
        await get_report_charts({
          object: {
            ...profile.selected_ad_account,
            id: profile.selected_ad_account.account_id,
            type: "ad_account",
            edge: profile.selected_ad_account.id,
          },
          date_preset: "last_30d",
        });
      } catch (error) {
        console.log(error.toString());
      }
    }
  }, [profile, report]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      console.log("Esc Key pressed");
      setReport({ ...report, selected_product: null });
      const firestore = firebase.firestore();

      firestore
        .collection("reports")
        .doc(profile.uid)
        .update({ selected_product: null })
        .then(() => {
          console.log("ok");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (report.products) {
      setProducts(
        _.orderBy(
          report.products,
          [
            function (o) {
              return parseInt(o.current.impressions);
            },
          ],
          ["desc"]
        )
      );
    }
    if (report.date_preset) {
      setPreset(report.date_preset);
    }
    if (report.selected_product && report.selected_product.chart) {
      setChartData(report.selected_product.chart);
    } else if (report.charts) {
      setChartData(report.charts);
    }
  }, [report]);

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.notification
    ) {
      setNotification(props.location.state.notification);
      let timeout = setTimeout(() => {
        const state = { ...props.history.location.state };
        delete state.notification;
        props.history.replace({ ...props.history.location, state });
      }, props.location.state.notification.timeout);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props]);

  const handleMakeAdmin = async (event) => {
    event.preventDefault();
    const functions = firebase.functions();
    const add_custom_claims = functions.httpsCallable("add_custom_claims");

    try {
      const response = await add_custom_claims({
        uid: "Sw3GfN1HPAMUvtkUo3Bt5n97e1J2",
        claims: {
          is_admin: true,
          access_level: 10,
        },
      });

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropsReset = () => {
    const state = { ...props.history.location.state };
    delete state.create_campaign;
    props.history.replace({ ...props.history.location, state });
  };

  console.log(currentUser);

  if (!profile.is_facebook_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-facebook" />
      </React.Fragment>
    );
  }

  if (!profile.is_email_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-email" />
      </React.Fragment>
    );
  }

  if (!profile.selected_business) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={window}
          claims={currentUser.claims}
        />
        <Bar
          title="Select Business Manager"
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <SelectBusiness profile={profile} />
        </Page>
        <BottomNavigationMobile />
        <Notification
          notification={notification}
          handleCloseNotification={handleCloseNotification}
        />
      </React.Fragment>
    );
  }

  if (!profile.selected_ad_account) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={window}
          claims={currentUser.claims}
        />
        <Bar
          title="Select Ad Account"
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <SelectAdAccount profile={profile} />
        </Page>
        <BottomNavigationMobile />
        <Notification
          notification={notification}
          handleCloseNotification={handleCloseNotification}
        />
      </React.Fragment>
    );
  }

  if (isMobile) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={window}
          report={report}
          setReport={setReport}
          claims={currentUser.claims}
        />
        <Bar
          title="Dashboard"
          actions={
            report && report.object && report.object.type === "ad_account" ? (
              <Button
                variant="contained"
                color="primary"
                className="create-campaign-button"
                onClick={() => {
                  setCreateCampaignModalOpen(true);
                }}
              >
                Create Campaign
              </Button>
            ) : null
          }
          handleDrawerToggle={handleDrawerToggle}
        />

        <Page>
          <div className="report">
            <div className="report-content">
              <div className="report-header">
                <ReportSelectedAdAccount
                  ad_account={profile.selected_ad_account}
                  uid={profile.uid}
                  is_active={
                    report &&
                    report.object &&
                    !report.selected_product &&
                    report.object.type === "ad_account"
                  }
                  is_mobile={true}
                />
                <ReportSelectedCampaign report={report} uid={profile.uid} />
                <DatePreset
                  preset={preset}
                  isMobile={true}
                  isLoading={
                    report.is_products_loading ||
                    report.is_metrics_loading ||
                    report.is_charts_loading
                  }
                  handlePresetChange={handlePresetChange}
                />
                <ReportMetrics
                  classes="mobile"
                  metrics={report.metrics}
                  setReportSelectedDialogOpen={setReportSelectedDialogOpen}
                  isLoading={report.is_metrics_loading}
                  profile={profile}
                />
              </div>
            </div>
            <ReportSidebar
              paging={report.paging}
              profile={profile}
              products={products}
              isMobile={isMobile}
              isLoading={
                report.is_products_loading ||
                report.is_metrics_loading ||
                report.is_charts_loading
              }
              handleProductSelect={handleProductSelect}
              preset={preset}
              selected={
                report && report.selected_product && report.selected_product.id
              }
              handleProductRemove={handleProductRemove}
              handleProductAdd={handleProductAdd}
              removed={
                report && report.object && report.object.type === "ad_account"
                  ? report.removed_products || []
                  : report && report.object && report.object.type === "campaign"
                  ? report.object.product_set.removed_products || []
                  : []
              }
              productToEdit={productToEdit}
              setProductToEdit={setProductToEdit}
              report={report}
            />
          </div>
        </Page>
        <Notification
          notification={notification}
          handleCloseNotification={handleCloseNotification}
        />
        <ReportSelectedMobile
          open={reportSelectedDialogOpen}
          setOpen={setReportSelectedDialogOpen}
          metrics={
            report.selected_product ? report.selected_product : report.metrics
          }
          handlePresetChange={handlePresetChange}
          preset={preset}
          profile={profile}
          data={chartData}
          report={report}
          isMobile={true}
        />
        <BottomNavigationMobile />
        <CreateCampaignModal
          open={createCampaignModalOpen}
          setOpen={setCreateCampaignModalOpen}
          profile={profile}
          report={report}
          handlePropsReset={handlePropsReset}
        />
        <Snackbar
          open={userMessage.open}
          autoHideDuration={userMessage.timeout}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={userMessage.severity}>
            {userMessage.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Menu
        profile={profile}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        window={window}
        report={report}
        setReport={setReport}
        claims={currentUser.claims}
      />

      <Bar
        title="Dashboard"
        actions={
          report && report.object && report.object.type === "ad_account" ? (
            <Button
              variant="contained"
              color="primary"
              className="create-campaign-button"
              onClick={() => {
                setCreateCampaignModalOpen(true);
              }}
            >
              Create Campaign
            </Button>
          ) : null
        }
        handleDrawerToggle={handleDrawerToggle}
      />

      <Page>
        {/* {currentUser && currentUser.claims && currentUser.claims.is_admin ? (
          <Button onClick={handleMakeAdmin}>Make Admin</Button>
        ) : null} */}
        <div className="report">
          <div className="report-content">
            <div className="page-content-width">
              {report.selected_product ? (
                <div className="report-header product">
                  <div className="back-title">
                    <Fab
                      size="small"
                      className="return-to-ad-account-arrow"
                      onClick={handleAdAccountSelect}
                    >
                      <NavigateBeforeIcon fontSize="large" />
                    </Fab>
                    <div className="header-content">
                      <h2>
                        {report.selected_product.id.split(",")[1]
                          ? report.selected_product.id.split(",")[1]
                          : report.selected_product.id}
                      </h2>
                      {report.selected_product.id.split(",")[0] ? (
                        <p>ID: {report.selected_product.id.split(",")[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  <Fab
                    size="small"
                    className="return-to-ad-account"
                    onClick={handleAdAccountSelect}
                  >
                    <CloseIcon />
                  </Fab>
                </div>
              ) : (
                <div className="report-header">
                  <ReportSelectedAdAccount
                    ad_account={profile.selected_ad_account}
                    uid={profile.uid}
                    is_active={
                      report &&
                      report.object &&
                      !report.selected_product &&
                      report.object.type === "ad_account"
                    }
                    is_mobile={false}
                  />
                  <ReportSelectedCampaign report={report} uid={profile.uid} />
                </div>
              )}

              {report &&
              report.charts &&
              report.charts.length === 0 &&
              report.products &&
              report.products.length === 0 &&
              !report.is_charts_loading &&
              !report.is_metrics_loading ? (
                <Alert severity="warning" className="empty-report">
                  No data to display for the selected metrics
                </Alert>
              ) : null}

              {/* {report.selected_product ? (
                  <h2 className="selected-product-title">
                    {report.selected_product.id.split(",")[1]}
                  </h2>
                ) : null} */}
              <DatePreset
                preset={
                  report.selected_product && report.selected_product.date_preset
                    ? report.selected_product.date_preset
                    : preset
                }
                isLoading={
                  report.is_products_loading ||
                  report.is_metrics_loading ||
                  report.is_charts_loading
                }
                handlePresetChange={handlePresetChange}
              />
              <ReportMetrics
                metrics={
                  report.selected_product
                    ? report.selected_product
                    : report.metrics
                }
                profile={profile}
                isLoading={report.is_metrics_loading}
              />
              <ReportCharts
                metrics={
                  report.selected_product
                    ? report.selected_product
                    : report.metrics
                }
                preset={
                  report.selected_product && report.selected_product.date_preset
                    ? report.selected_product.date_preset
                    : preset
                }
                data={chartData}
                profile={profile}
                isLoading={report.is_charts_loading}
                report={report}
              />
            </div>
          </div>
          <ReportSidebar
            paging={report.paging}
            profile={profile}
            products={products}
            isMobile={isMobile}
            isLoading={
              report.is_products_loading ||
              report.is_metrics_loading ||
              report.is_charts_loading
            }
            handleProductSelect={handleProductSelect}
            preset={preset}
            selected={
              report && report.selected_product && report.selected_product.id
            }
            handleProductRemove={handleProductRemove}
            handleProductAdd={handleProductAdd}
            removed={
              report && report.object && report.object.type === "ad_account"
                ? report.removed_products || []
                : report && report.object && report.object.type === "campaign"
                ? report.object.product_set.removed_products || []
                : []
            }
            productToEdit={productToEdit}
            setProductToEdit={setProductToEdit}
            report={report}
          />
        </div>
      </Page>
      <CreateCampaignModal
        open={createCampaignModalOpen}
        setOpen={setCreateCampaignModalOpen}
        profile={profile}
        report={report}
        handlePropsReset={handlePropsReset}
      />
      <Notification
        notification={notification}
        handleCloseNotification={handleCloseNotification}
      />
      <Snackbar
        open={userMessage.open}
        autoHideDuration={userMessage.timeout}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Dashboard;
