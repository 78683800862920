import React, { useState, useEffect, useContext, useRef } from "react";

import { AuthContext } from "../config/AuthProvider";
import { Redirect } from "react-router-dom";
import Menu from "../components/Menu";
import Notification from "../components/Notification";
import Bar from "../components/Bar";
import Page from "../components/Page";
import BottomNavigationMobile from "../components/BottomNavigationMobile";
import Button from "@material-ui/core/Button";
import EditCampaignForm from "../components/EditCampaignForm";
import firebase from "../config/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import dayjs from "dayjs";
import _ from "lodash";

const Campaign = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const [campaign, setCampaign] = useState({
    is_loading: true,
    is_empty: true,
  });
  const [scopedIsLoading, setScopedIsLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  useEffect(() => {
    const firestore = firebase.firestore();
    firestore
      .collection("campaigns")
      .doc(props.match.params.id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          if (componentIsMounted.current) {
            setCampaign({
              ...doc.data(),
              id: doc.id,
              is_loading: false,
              is_empty: false,
            });
          }
        } else {
          if (componentIsMounted.current) {
            setCampaign({ is_loading: false, is_empty: true });
          }
        }
      });
  }, []);

  useEffect(() => {
    if (campaign.history) {
      let h = _.sortBy(campaign.history, [
        function (o) {
          return dayjs(o.updated_at.seconds).unix();
        },
      ]).reverse();

      setHistory(h);
    }
  }, [campaign]);

  const handleSeeReport = async () => {
    if (componentIsMounted.current) {
      setScopedIsLoading(true);
    }
    const functions = firebase.functions();
    const get_report_products = functions.httpsCallable("get_report_products");
    const get_report_metrics = functions.httpsCallable("get_report_metrics");
    const get_report_charts = functions.httpsCallable("get_report_charts");
    const get_selected_product = functions.httpsCallable(
      "get_selected_product"
    );

    try {
      await firebase
        .firestore()
        .collection("reports")
        .doc(profile.uid)
        .set({
          object: {
            ...campaign,
            edge: campaign.campaign_fb_id,
            type: "campaign",
            timezone: profile.selected_ad_account.timezone,
          },
          is_metrics_loading: true,
          is_charts_loading: true,
          is_products_loading: true,
          date_preset: "last_7d",
          cache: [],
          products: [],
          selected_product: null,
          updated_at: new Date(),
          uid: profile.uid,
        });
      await get_report_products({
        object: {
          ...campaign,
          edge: campaign.campaign_fb_id,
          type: "campaign",
          timezone: profile.selected_ad_account.timezone,
        },
        date_preset: "last_7d",
        after: null,
      });
      await get_report_metrics({
        object: {
          ...campaign,
          edge: campaign.campaign_fb_id,
          type: "campaign",
          timezone: profile.selected_ad_account.timezone,
        },
        date_preset: "last_7d",
      });
      await get_report_charts({
        object: {
          ...campaign,
          edge: campaign.campaign_fb_id,
          type: "campaign",
          timezone: profile.selected_ad_account.timezone,
        },
        date_preset: "last_7d",
      });
      props.history.push("/dashboard");
    } catch (error) {
      if (componentIsMounted.current) {
        setScopedIsLoading(false);
      }
      console.log(error.toString());
    }
  };

  console.log("Campaign props", campaign);

  if (!profile.is_facebook_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-facebook" />
      </React.Fragment>
    );
  }

  if (!profile.is_email_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-email" />
      </React.Fragment>
    );
  }

  if (campaign.is_loading) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={window}
          claims={currentUser.claims}
        />
        <Bar
          title={"Campaign"}
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <div className="page-no-sidebar">
            <div className="loading-component">
              <p>Gathering data</p> <CircularProgress size={20} />
            </div>
          </div>
        </Page>
        <Notification
          notification={notification}
          handleCloseNotification={handleCloseNotification}
        />
        <BottomNavigationMobile />
      </React.Fragment>
    );
  }

  if (!campaign.is_loading && campaign.is_empty) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={window}
          claims={currentUser.claims}
        />
        <Bar
          title={"Campaign"}
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <div className="page-no-sidebar">
            <p>
              There's no campaign with that id. Because we don't want to
              interfere with other apps you might be using or the campaigns
              you're creating from Facebook, we're listing only the campaigns
              created through our app.
            </p>
          </div>
        </Page>
        <Notification
          notification={notification}
          handleCloseNotification={handleCloseNotification}
        />
        <BottomNavigationMobile />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Menu
        profile={profile}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        window={window}
        claims={currentUser.claims}
      />
      <Bar
        title={"Campaign"}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={handleSeeReport}
            endIcon={scopedIsLoading ? <CircularProgress size={18} /> : null}
          >
            See Report
          </Button>
        }
        handleDrawerToggle={handleDrawerToggle}
      />
      <Page>
        <div className="page-no-sidebar">
          <h3 style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => {
                props.history.push("/campaigns");
              }}
              className="back-button"
            >
              <NavigateBeforeIcon />
            </Button>
            Facebook ID: {campaign.campaign_fb_id}
          </h3>
          <EditCampaignForm campaign={campaign} profile={profile} />

          {campaign.history ? (
            <div className="campaign-history">
              {history.map((update) => {
                return (
                  <div className="update" key={update.updated_at.seconds}>
                    <h3>{update.title}</h3>
                    <p className="removed-products-title">
                      <strong>REMOVED PRODUCTS:</strong>
                      <span>impressions/roas</span>
                    </p>
                    {update.removed_products &&
                    update.removed_products.length > 0 ? (
                      <ul>
                        {update.removed_products.map((product) => {
                          return (
                            <li
                              key={`${update.updated_at.seconds}-${product.product_id}`}
                            >
                              <span>{product.product_id}</span>
                              <span>{product.impressions}</span>
                              <span>
                                {parseFloat(product.purchase_roas).toFixed(2)}x
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <p>
                        No products were removed from the product set on this
                        update.
                      </p>
                    )}
                    <p className="updated-at">
                      Updated on:{" "}
                      {dayjs
                        .unix(update.updated_at.seconds)
                        .tz(campaign.ad_account.timezone)
                        .format("MMMM D, YYYY h:mm A")}
                      <br />
                      Product set: {campaign.product_set.id}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </Page>
      <Notification
        notification={notification}
        handleCloseNotification={handleCloseNotification}
      />
      <BottomNavigationMobile />
    </React.Fragment>
  );
};

export default Campaign;
