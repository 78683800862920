import React from "react";
import logo from "../images/logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const useSyles = makeStyles((theme) => {
  return {
    logo: {
      maxWidth: "70px",
      marginBottom: "30px",
      marginTop: "40px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    },
    title: {
      textAlign: "center",
      marginBottom: "30px",
      fontWeight: "normal",
      fontSize: "32px",
    },
  };
});

const Privacy = (props) => {
  const classes = useSyles();
  return (
    <div className="legal-page">
      <Container maxWidth="md">
        <Grid container>
          <Grid item md={12}>
            <Link to="/">
              <img src={logo} alt="Zbang" className={classes.logo} />
            </Link>
            <h1 className={classes.title}>Privacy Policy</h1>
            <p>
              Like everywhere zbang.ai collects your personal information. Since
              we have your details, it only seems fair for you to have ours.
            </p>
            <p>
              Zbang.ai is owned by Nest Place Ltd a company incorporated in
              England and our company number is <strong>12942659</strong>.
            </p>
            <p>
              Registered office address: 65 London Wall, London, England, EC2M
              5TU.
            </p>

            <h3>1. WHATSORTOFPERSONALDATADOWECOLLECT?</h3>
            <p>
              When You create an account with Zbang, we are required to collect
              some information on You before you can use Our Services. Our
              records may include all or some of the following information about
              You:
            </p>
            <ul>
              <li>
                Your name, address, email address, telephone number, payment
                card details, etc.
              </li>
              <li>
                Details of Your correspondence with Zbang and any other
                information you have given to us.
              </li>
              <li>
                Details of Your transactions and other activities carried out on
                Our Services.
              </li>
              <li>
                Details of Your visits to our websites and/or Apps including,
                but not limited to, traffic data, location data, IP address,
                device ID, web logs, page views, ad data and other similar data,
                whether this is required for our own purposes or otherwise; and
              </li>
              <li>
                Your response to marketing campaigns from us or through our
                third parties.
              </li>
            </ul>
            <h3>2. CONSENT</h3>
            <p>
              The law on data protection sets out a number of different reasons
              for which a company may collect and process your personal data,
              including.
            </p>
            <ul>
              <li>
                Contractual obligations
                <br /> When You shop Our services, You are entering a contract
                and that’s one of the bases We use for processing your data. We
                need your personal data to comply with our contractual
                obligations.
              </li>
              <li>
                Consent
                <br /> We can collect and process your data with Your consent.
                For example when registering to Our newsletter.
              </li>
              <li>
                Legal compliance
                <br />
                If the law requires us to, We may need to collect and process
                Your data.
              </li>
              <li>
                Legitimate interest
                <br />
                In specific situations, We require Your data to pursue our
                legitimate interests in a way which might reasonably be expected
                as part of running our business and which does not materially
                impact your rights, freedom or interests.
              </li>
            </ul>
            <p>How do you get my consent?</p>
            <p>
              When You provide Us with personal information to complete a
              transaction, verify your credit card, place an order, we imply
              that you consent to our collecting it and using it for that
              specific reason only.
            </p>
            <p>
              If We ask for Your personal information for a secondary reason,
              like marketing, we will either ask you directly for your expressed
              consent, or provide you with an opportunity to say no.
            </p>
            <p>How do I withdraw my consent?</p>
            <p>
              If after you opt-in, You change your mind, You may withdraw Your
              consent for us to contact you, for the continued collection, use
              or disclosure of your information, at anytime, by contacting us at
              go@zbang.ai .com or mailing us at: Nest Place Ltd 65 London Wall,
              London, England, EC2M 5TU.
            </p>
            <h3>3. DISCLOSURE</h3>
            <p>
              We may disclose Your personal information if we are required by
              law to do so or if you violate our Terms of Service.
            </p>
            <p>
              Payment:
              <br />
              If you choose a direct payment gateway to complete your purchase,
              then we do not store your credit card data. It is encrypted
              through the Payment Card Industry Data Security Standard
              (PCI-DSS). Your purchase transaction data is stored only as long
              as is necessary to complete your purchase transaction. After that
              is complete, your purchase transaction information is deleted.
            </p>
            <p>
              All direct payment gateways adhere to the standards set by PCI-DSS
              as managed by the PCI Security Standards Council, which is a joint
              effort of brands like Visa, MasterCard, American Express and
              Discover.
            </p>
            <p>
              PCI-DSS requirements help ensure the secure handling of credit
              card information by our website and its service providers.
            </p>
            <h3>4. THIRD-PARTYSERVICES</h3>
            <p>
              There are circumstances where We may share some of Your data with
              others:
            </p>
            <ul>
              <li>
                IT companies who support our website and other business systems.
              </li>
              <li>
                Google/Facebook/Instagram to show you products that might
                interest you while you’re browsing the internet. This is based
                on either your marketing consent or your acceptance of cookies
                on our websites.
              </li>
              <li>
                We may also be required to disclose your personal data to the
                police or other enforcement, regulatory or Government body, in
                your country of origin or elsewhere, upon a valid request to do
                so.
              </li>
            </ul>
            <p>
              In general, the third-party providers used by us will only
              collect, use and disclose your information to the extent necessary
              to allow them to perform the services they provide to. However,
              certain third-party service providers, such as payment gateways
              and other payment transaction processors, have their own privacy
              policies in respect to the information we are required to provide
              to them for your purchase-related transactions.
            </p>
            <p>
              For these providers, we recommend that you read their privacy
              policies so you can understand the manner in which your personal
              information will be handled by these providers.
            </p>
            <p>
              Links
              <br />
              When you click on links on our website, they may direct you away
              from our site. We are not responsible for the privacy practices of
              other sites and encourage you to read their privacy statements.
            </p>
            <h3>5. SECURITY</h3>
            <p>
              We know how much data security matters to all our customers. With
              this in mind We will treat Your data with the utmost care and take
              all appropriate steps to protect it.
            </p>
            <p>
              To protect your personal information, we take reasonable
              precautions and follow industry best practices to make sure it is
              not inappropriately lost, misused, accessed, disclosed, altered or
              destroyed.
            </p>
            <p>
              We secure access to all transactional areas of our websites and
              apps using ‘https’ technology. Access to your personal data is
              password-protected.
            </p>
            <p>
              We regularly monitor our system for possible vulnerabilities and
              attacks, and we carry out penetration testing to identify ways to
              further strengthen security.
            </p>
            <h3>6. AGE OF CONSENT</h3>
            <p>
              By using this site, You represent that You are at least the age of
              majority in your state or province of residence, or that you are
              the age of majority in your state or province of residence and You
              have given Us your consent to allow any of your minor dependents
              to use this site.
            </p>
            <h3>7. CHANGES TO THIS PRIVACY POLICY</h3>
            <p>
              We reserve the right to modify this privacy policy at any time, so
              please review it frequently. Changes and clarifications will take
              effect immediately upon their posting on the website. If We make
              material changes to this policy, we will notify You here that it
              has been updated, so that you are aware of what information we
              collect, how we use it, and under what circumstances, if any, we
              use and/or disclose it.
            </p>
            <p>
              If our business is acquired or merged with another company, your
              information may be transferred to the new owners so that we may
              continue to sell products to you.
            </p>
            <h3>8. YOUR RIGHTS</h3>
            <ul>
              <li>
                You have the right to be informed about how your personal data
                is being used. This is done, for example, through this privacy
                notice.
              </li>
              <li>
                You have a right to access any personal data we hold about you
                and we’ll deal with any subject access request within 30 days.
              </li>
              <li>
                You have the right to ask us to stop or pause processing your
                data, erase your data or amend your data.
              </li>
              <li>
                You have the right to stop receiving direct marketing. Just ask
                us to take you off the list.
              </li>
              <li>
                You have the right of portability over your data (i.e. you can
                ask us to pass the data we hold on you to a third party in
                machine-readable format).
              </li>
              <li>
                If you have any complaints, you can contact us (see details
                below) and we’ll do our utmost to get to the bottom of things.
                If, after contacting our Data Protection Officer you feel your
                complaint has not been dealt with satisfactorily, you have the
                right to contact the supervisory authority. The supervisory
                authority for data protection in the UK is the Information
                Commissioner’s Office (ICO) which you can contact on 0303 123
                1113 or via their website at ico.org.uk.
              </li>
            </ul>
            <h3>QUESTIONS AND CONTACT INFORMATION</h3>
            <p>
              go@zbang.ai.com or mailing us at: Nest Place Ltd 65 London Wall,
              London, England, EC2M 5TU.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Privacy;
