import React from "react";
import NotificationsProvider from "../config/NotificationsProvider";
import { SnackbarProvider } from "notistack";
import ScrollRestoration from "../components/ScrollRestoration";
import NotificationMessage from "../components/NotificationMessage";
import Dashboard from "./Dashboard";
import Campaigns from "./Campaigns";
import Campaign from "./Campaign";
import Notifications from "./Notifications";
import Settings from "./Settings";
import Upgrade from "./Upgrade";
import Plan from "./Plan";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";

import ConnectFacebook from "./ConnectFacebook";
import ConnectEmail from "./ConnectEmail";

const Authenticated = (props) => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      dense
      preventDuplicate
      maxSnack={1}
      content={(key, message) => (
        <NotificationMessage
          id={key}
          onClickHandler={
            message.click_action
              ? () => {
                  props.history.push(message.in_app_click_action);
                }
              : null
          }
          notification={message}
        />
      )}
    >
      <NotificationsProvider>
        <ScrollRestoration>
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/campaigns" exact component={Campaigns} />
          <Route path="/campaigns/:id" exact component={Campaign} />
          <Route path="/notifications" exact component={Notifications} />
          <Route path="/settings" exact component={Settings} />
          {/* <Route path="/settings/upgrade" exact component={Upgrade} /> */}
          <Route path="/settings/account" exact component={Plan} />
          <Route path="/connect-facebook" exact component={ConnectFacebook} />
          <Route path="/connect-email" exact component={ConnectEmail} />
        </ScrollRestoration>
      </NotificationsProvider>
    </SnackbarProvider>
  );
};

export default withRouter(Authenticated);
