import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import ReportSelectedAdAccount from "../components/ReportSelectedAdAccount";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import _ from "lodash";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DatePreset from "./DatePreset";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import firebase from "../config/firebase";
import Switch from "@material-ui/core/Switch";
import { withRouter } from "react-router-dom";
import Info from "./Info";
import { date_preset_name } from "../config/utils";

const CreateCampaignModal = (props) => {
  const { open, setOpen, profile, report, is_mobile, handlePropsReset } = props;
  const [productCatalogs, setProductCatalogs] = useState(null);
  const [selectedProductCatalog, setSelectedProductCatalog] = useState("");
  const [pixels, setPixels] = useState(null);
  const [selectedPixel, setSelectedPixel] = useState("");
  const [pages, setPages] = useState(null);
  const [selectedPage, setSelectedPage] = useState("");
  const fullScreen = useMediaQuery("(max-width:1099px)");
  const [preset, setPreset] = useState("last_3d");
  const [impressions, setImpressions] = useState(0);
  const [roas, setRoas] = useState(0);
  const [dailyBudget, setDailyBudget] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const [status, setStatus] = useState("ACTIVE");
  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "success",
    timeout: 12000,
    open: false,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleStatusChange = (event) => {
    if (event.target.checked) {
      setStatus("ACTIVE");
    } else {
      setStatus("PAUSED");
    }
  };

  const handleUserMessageClose = () => {
    setUserMessage({ message: "", severity: "", open: false });
  };

  const handleClose = () => {
    setOpen(false);
    handlePropsReset();
  };

  const handlePresetChange = (preset) => {
    setPreset(preset);
  };

  const handleChangeImpressions = (event) => {
    setImpressions(event.target.value);
  };

  const handleChangeRoas = (event) => {
    setRoas(event.target.value);
  };

  const handleChangeDailyBudget = (event) => {
    setDailyBudget(event.target.value);
  };

  const handleChangeBidAmount = (event) => {
    setBidAmount(event.target.value);
  };

  const handleChangePixel = (event) => {
    setSelectedPixel(event.target.value);
  };

  const handleChangePage = (event) => {
    setSelectedPage(event.target.value);
  };

  useEffect(() => {
    if (profile.product_catalogs && profile.product_catalogs.length > 0) {
      const availableProductCatalogs = _.filter(
        profile.product_catalogs,
        function (o) {
          return (
            o.business.id &&
            o.business.id === profile.selected_ad_account.business.id
          );
        }
      );
      setProductCatalogs(availableProductCatalogs);
    }

    if (profile.pixels && profile.pixels.length > 0) {
      const availablePixels = _.filter(profile.pixels, function (o) {
        return (
          o.owner_business &&
          o.owner_business.id === profile.selected_ad_account.business.id
        );
      });
      setPixels(availablePixels);
    }

    if (profile.pages && profile.pages.length > 0) {
      const availablePages = _.filter(profile.pages, function (o) {
        return (
          o.business &&
          o.business.id === profile.selected_ad_account.business.id
        );
      });
      setPages(availablePages);
    }
  }, [profile]);

  useEffect(() => {
    if (productCatalogs && productCatalogs.length > 0) {
      setSelectedProductCatalog(productCatalogs[0].id);
    }
  }, [productCatalogs]);

  useEffect(() => {
    if (pixels && pixels.length > 0) {
      setSelectedPixel(pixels[0].id);
    }
  }, [pixels]);

  useEffect(() => {
    if (pages && pages.length > 0) {
      setSelectedPage(pages[0].id);
    }
  }, [pages]);

  const handleChangeSelectdProductCatalog = (event) => {
    setSelectedProductCatalog(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (componentIsMounted.current) {
      setIsLoading(true);
    }
    if (
      parseInt(impressions) > 0 &&
      parseInt(roas) > 0 &&
      parseInt(dailyBudget) > 0 &&
      parseInt(bidAmount) > 0 &&
      selectedProductCatalog &&
      selectedPixel &&
      selectedPage &&
      link.includes(".") &&
      !link.includes("www.") &&
      !link.includes("https://") &&
      !link.includes("http://")
    ) {
      try {
        const functions = firebase.functions();
        const launch_campaign = functions.httpsCallable("launch_campaign");
        const campaign = await launch_campaign({
          status: status,
          ad_account: profile.selected_ad_account,
          product_catalog: _.find(profile.product_catalogs, function (o) {
            return o.id === selectedProductCatalog;
          }),
          date_preset: preset,
          impressions: parseInt(impressions),
          roas: parseFloat(parseFloat(roas).toFixed(2)),
          daily_budget: parseInt(dailyBudget),
          bid_amount: parseInt(bidAmount),
          pixel: _.find(profile.pixels, function (o) {
            return o.id === selectedPixel;
          }),
          page: _.find(profile.pages, function (o) {
            return o.id === selectedPage;
          }),
          link: link,
          removed_products:
            report && report.removed_products ? report.removed_products : [],
          uid: profile.uid,
        });
        const campaign_id = campaign.data.id;
        const campaign_fb_id = campaign.data.campaign_fb_id;

        if (componentIsMounted.current) {
          setIsLoading(false);
          setUserMessage({
            message: `Camapign with id ${campaign_fb_id} has been successfully created!`,
            severity: "success",
            timeout: 5000,
            open: true,
          });
          setOpen(false);
          props.history.push(`/campaigns/${campaign_id}`);
        }

        //Set the new campaign as the current object in the report so it persists on refresh
      } catch (error) {
        if (componentIsMounted.current) {
          setIsLoading(false);
          setUserMessage({
            message: error.message,
            severity: "error",
            timeout: 5000,
            open: true,
          });
        }
      }
      // console.log({
      //   status: status,
      //   ad_account: profile.selected_ad_account,
      //   product_catalog: _.find(profile.product_catalogs, function (o) {
      //     return o.id === selectedProductCatalog;
      //   }),
      //   date_preset: preset,
      //   impressions: parseInt(impressions),
      //   roas: parseFloat(parseFloat(roas).toFixed(2)),
      //   daily_budget: parseInt(dailyBudget),
      //   bid_amount: parseInt(bidAmount),
      //   pixel: _.find(profile.pixels, function (o) {
      //     return o.id === selectedPixel;
      //   }),
      //   pixel: _.find(profile.pages, function (o) {
      //     return o.id === selectedPage;
      //   }),
      //   is_campaign_loading: true,
      //   uid: profile.uid,
      // });
    } else {
      if (componentIsMounted.current) {
        setIsLoading(false);
        setUserMessage({
          message: "Please check the values and try again!",
          severity: "warning",
          timeout: 5000,
          open: true,
        });
      }
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="CreateCampaignModal"
        className="dialog"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle className="dialog-title">
            <div className="title-content">Create Campaign</div>
            <Fab size="small" onClick={handleClose}>
              <CloseIcon />
            </Fab>
          </DialogTitle>
          <DialogContent className="dialog-content">
            <FormControl variant="outlined" fullWidth>
              <div className="campaign-status">
                <h4>Status</h4>
                <div>
                  <Switch
                    checked={status === "ACTIVE"}
                    onChange={handleStatusChange}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <p>{status === "ACTIVE" ? "Active" : "Draft"}</p>
                </div>
              </div>
            </FormControl>
            <div className="create-campaign-selected-ad-account">
              <h3>
                Ad Account{" "}
                <Info
                  html={
                    <React.Fragment>
                      <p>Select the Facebook ad account.</p>
                    </React.Fragment>
                  }
                />
              </h3>
              <ReportSelectedAdAccount
                ad_account={profile.selected_ad_account}
                uid={profile.uid}
                is_mobile={true}
                is_active={true}
              />
              <FormControl component="fieldset" fullWidth>
                <h3>
                  Product Catalog{" "}
                  <Info
                    html={
                      <React.Fragment>
                        <p>
                          Select the Facebook catalog you want to advertise.
                        </p>
                      </React.Fragment>
                    }
                  />
                </h3>
                <RadioGroup
                  aria-label="product-catalog"
                  name="product-catalog"
                  value={selectedProductCatalog}
                  onChange={handleChangeSelectdProductCatalog}
                >
                  {productCatalogs &&
                    productCatalogs.length > 0 &&
                    productCatalogs.map((i, index) => {
                      return (
                        <FormControlLabel
                          value={i.id}
                          control={<Radio />}
                          label={i.name}
                          key={i.id}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
              <h3>
                Date Preset{" "}
                <Info
                  html={
                    <React.Fragment>
                      <p>
                        Select the time frame we apply Impressions/ROAS rules.
                      </p>
                    </React.Fragment>
                  }
                />
              </h3>
              <DatePreset
                classes="justify-flex-start"
                handlePresetChange={handlePresetChange}
                preset={preset}
                isLoading={false}
              />
              <h3>
                Rules{" "}
                <Info
                  html={
                    <React.Fragment>
                      <p>
                        Ex: if {preset === "this_year" ? "" : "in the"}{" "}
                        <span style={{ textTransform: "lowercase" }}>
                          {date_preset_name(preset)}
                        </span>{" "}
                        the number of impressions is grater than {impressions}{" "}
                        but the ROAS is smaller than {roas}x we consider the
                        product unprofitable and remove it from the product set.
                      </p>
                    </React.Fragment>
                  }
                />
              </h3>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="daily-budget">Impressions</InputLabel>
                <OutlinedInput
                  id="impressions"
                  value={impressions}
                  onChange={handleChangeImpressions}
                  labelWidth={90}
                  type="number"
                />
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="roas">ROAS</InputLabel>
                <OutlinedInput
                  id="roas"
                  value={roas}
                  onChange={handleChangeRoas}
                  labelWidth={48}
                  type="number"
                />
              </FormControl>
              <h3>
                Daily Budget{" "}
                <Info
                  html={
                    <React.Fragment>
                      <p>
                        If you choose a daily budget, you may spend more on some
                        days and less on others, but will maintain an average
                        daily budget.
                      </p>
                    </React.Fragment>
                  }
                />
              </h3>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="daily-budget">Daily Budget</InputLabel>
                <OutlinedInput
                  id="daily-budget"
                  value={dailyBudget}
                  onChange={handleChangeDailyBudget}
                  endAdornment={
                    <InputAdornment position="start">
                      {profile.selected_ad_account.currency}
                    </InputAdornment>
                  }
                  type="number"
                  labelWidth={95}
                />
              </FormControl>
              <h3>
                Bid Amount{" "}
                <Info
                  html={
                    <React.Fragment>
                      <p>
                        Set a bid to control your maximum bid in every auction.
                      </p>
                    </React.Fragment>
                  }
                />
              </h3>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="bid-amount">Bid Amount</InputLabel>
                <OutlinedInput
                  id="bid-amount"
                  value={bidAmount}
                  onChange={handleChangeBidAmount}
                  endAdornment={
                    <InputAdornment position="start">
                      {profile.selected_ad_account.currency}
                    </InputAdornment>
                  }
                  type="number"
                  labelWidth={90}
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <h3>
                  Pixel{" "}
                  <Info
                    html={
                      <React.Fragment>
                        <p>
                          Facebook will use the following pixel set for tracking
                        </p>
                      </React.Fragment>
                    }
                  />
                </h3>
                <RadioGroup
                  aria-label="pixel"
                  name="pixel"
                  value={selectedPixel}
                  onChange={handleChangePixel}
                >
                  {pixels &&
                    pixels.length > 0 &&
                    pixels.map((i, index) => {
                      return (
                        <FormControlLabel
                          value={i.id}
                          control={<Radio />}
                          label={i.name}
                          key={i.id}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <h3>
                  Page{" "}
                  <Info
                    html={
                      <React.Fragment>
                        <p>
                          Your Facebook Page that represents your business in
                          ads.
                        </p>
                      </React.Fragment>
                    }
                  />
                </h3>
                <RadioGroup
                  aria-label="pixel"
                  name="pixel"
                  value={selectedPage}
                  onChange={handleChangePage}
                >
                  {pages &&
                    pages.length > 0 &&
                    pages.map((i, index) => {
                      return (
                        <FormControlLabel
                          value={i.id}
                          control={<Radio />}
                          label={i.name}
                          key={i.id}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
              <h3>
                Website URL{" "}
                <Info
                  html={
                    <React.Fragment>
                      <p>
                        Enter the URL for the web page that you want people to
                        visit
                      </p>
                    </React.Fragment>
                  }
                />
              </h3>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="Link">Link</InputLabel>
                <OutlinedInput
                  id="Link"
                  value={link}
                  onChange={handleLinkChange}
                  startAdornment={
                    <InputAdornment position="start">
                      https://www.
                    </InputAdornment>
                  }
                  type="text"
                  labelWidth={32}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="cancel-button">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Launch
              {isLoading ? <CircularProgress size={18} /> : null}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={userMessage.open}
        autoHideDuration={userMessage.timeout}
        onClose={handleUserMessageClose}
      >
        <Alert onClose={handleUserMessageClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withRouter(CreateCampaignModal);
