import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../config/AuthProvider";
import { Redirect } from "react-router-dom";
import Menu from "../components/Menu";
import Notification from "../components/Notification";
import Bar from "../components/Bar";
import Page from "../components/Page";
import BottomNavigationMobile from "../components/BottomNavigationMobile";
import { CircularProgress } from "@material-ui/core";
import firebase from "../config/firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CampaignList from "../components/CampaignList";
import Button from "@material-ui/core/Button";
import SelectBusiness from "../components/SelectBusiness";
import SelectAdAccount from "../components/SelectAdAccount";

const Campaigns = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const firestore = firebase.firestore();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [orderBy, setOrderBy] = useState("created_at");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [nextBatch, setNextBatch] = useState(
    firestore.collection("campaigns").where("uid", "==", profile.uid)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [startAfterDoc, setStartAfterDoc] = useState(null);
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(true);
  const [isCampaignsEmpty, setIsCampaignsEmpty] = useState(true);
  const [limit, setLimit] = useState(10);
  const [wasPaginated, setWasPaginated] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "success",
    timeout: 12000,
    open: false,
  });
  const [scopedIsLoading, setScopedIsLoading] = useState(false);
  const [report, setReport] = useState({
    is_loading: true,
    is_empty: true,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const reportRef = firebase
      .firestore()
      .collection("reports")
      .doc(profile.uid);
    reportRef.onSnapshot((doc) => {
      if (doc.exists) {
        if (componentIsMounted.current) {
          setReport({ ...doc.data(), is_loading: false, is_empty: false });
        }
      } else {
        setReport({
          is_loading: false,
          is_empty: true,
        });
        //requestReport();
      }
    });
  }, []);

  const handleUserMessageClose = () => {
    setUserMessage({ message: "", severity: "", open: false });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  const handlePagination = () => {
    setIsCampaignsLoading(true);
    nextBatch
      .orderBy(orderBy, orderDirection)
      .startAfter(startAfterDoc)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        setStartAfterDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        let tempCampaigns = campaigns;
        let isEmpty = true;
        querySnapshot.forEach(function (doc) {
          isEmpty = false;
          tempCampaigns.push({ ...doc.data(), id: doc.id });
        });
        if (componentIsMounted.current) {
          setIsCampaignsLoading(false);
          setIsCampaignsEmpty(isEmpty);
          setCampaigns(tempCampaigns);
          setCurrentPage(currentPage + 1);
          setWasPaginated(true);
        }
      })
      .catch((error) => {
        if (componentIsMounted.current) {
          setIsCampaignsLoading(false);
          setUserMessage({
            message: "Please check the values and try again!",
            severity: "warning",
            timeout: 5000,
            open: true,
          });
        }
      });
  };

  useEffect(() => {
    nextBatch
      .orderBy(orderBy, orderDirection)
      .limit(limit * currentPage)
      .onSnapshot(function (querySnapshot) {
        setStartAfterDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        let tempCampaigns = [];
        let isEmpty = true;
        querySnapshot.forEach(function (doc) {
          isEmpty = false;
          tempCampaigns.push({ ...doc.data(), id: doc.id });
        });
        if (componentIsMounted.current) {
          setIsCampaignsLoading(false);
          setIsCampaignsEmpty(isEmpty);
          setCampaigns(tempCampaigns);
        }
      });
  }, [limit, orderBy, orderDirection]);

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.notification
    ) {
      setNotification(props.location.state.notification);
      let timeout = setTimeout(() => {
        const state = { ...props.history.location.state };
        delete state.notification;
        props.history.replace({ ...props.history.location, state });
      }, props.location.state.notification.timeout);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props]);

  const handleCreateCampaign = async () => {
    const functions = firebase.functions();
    const get_report_products = functions.httpsCallable("get_report_products");
    const get_report_metrics = functions.httpsCallable("get_report_metrics");
    const get_report_charts = functions.httpsCallable("get_report_charts");

    if (componentIsMounted.current) {
      setScopedIsLoading(true);
    }

    if (report && report.object && report.object.type === "ad_account") {
      props.history.push("/dashboard", {
        create_campaign: true,
      });
    } else {
      try {
        await firebase.firestore().collection("reports").doc(profile.uid).set(
          {
            is_metrics_loading: true,
            is_charts_loading: true,
            is_products_loading: true,
            date_preset: "last_7d",
            cache: [],
            selected_product: null,
            updated_at: new Date(),
            uid: profile.uid,
          },
          { merge: true }
        );
        await get_report_products({
          object: {
            ...profile.selected_ad_account,
            id: profile.selected_ad_account.account_id,
            type: "ad_account",
            edge: profile.selected_ad_account.id,
          },
          date_preset: "last_7d",
          after: null,
        });
        await get_report_metrics({
          object: {
            ...profile.selected_ad_account,
            id: profile.selected_ad_account.account_id,
            type: "ad_account",
            edge: profile.selected_ad_account.id,
          },
          date_preset: "last_7d",
        });
        await get_report_charts({
          object: {
            ...profile.selected_ad_account,
            id: profile.selected_ad_account.account_id,
            type: "ad_account",
            edge: profile.selected_ad_account.id,
          },
          date_preset: "last_7d",
        });
        props.history.push("/dashboard", {
          create_campaign: true,
        });
      } catch (error) {
        console.log(error.toString());
      }
    }
  };

  if (!profile.is_facebook_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-facebook" />
      </React.Fragment>
    );
  }

  if (!profile.is_email_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-email" />
      </React.Fragment>
    );
  }

  if (!profile.selected_business) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={window}
          claims={currentUser.claims}
        />
        <Bar
          title="Select Business Manager"
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <SelectBusiness profile={profile} />
        </Page>
        <BottomNavigationMobile />
        <Notification
          notification={notification}
          handleCloseNotification={handleCloseNotification}
        />
      </React.Fragment>
    );
  }

  if (!profile.selected_ad_account) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={window}
          claims={currentUser.claims}
        />
        <Bar
          title="Select Ad Account"
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <SelectAdAccount profile={profile} />
        </Page>
        <BottomNavigationMobile />
        <Notification
          notification={notification}
          handleCloseNotification={handleCloseNotification}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Menu
        profile={profile}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        window={window}
        claims={currentUser.claims}
      />
      <Bar
        title={
          campaigns.isLoading ? (
            <span>
              Campaigns <CircularProgress size={18} />
            </span>
          ) : (
            "Campaigns"
          )
        }
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCampaign}
            endIcon={scopedIsLoading ? <CircularProgress size={15} /> : null}
            disabled={scopedIsLoading}
          >
            Create Campaign
          </Button>
        }
        handleDrawerToggle={handleDrawerToggle}
      />
      <Page>
        <div className="page-no-sidebar">
          <CampaignList
            campaigns={campaigns}
            handlePagination={handlePagination}
            wasPaginated={wasPaginated}
            startAfterDoc={startAfterDoc}
            currentPage={currentPage}
            limit={limit}
            setLimit={setLimit}
            isLoading={isCampaignsLoading}
            isEmpty={isCampaignsEmpty}
            isLastPage={campaigns.length !== currentPage * limit}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            profile={profile}
            handleCreateCampaign={handleCreateCampaign}
            isCampaignCreating={scopedIsLoading}
          />
          <p style={{ opacity: 0.5 }}>
            <em>
              *Because we don't want to interfere with other apps you might be
              using or the campaigns you're creating from Facebook, we're
              listing only the campaigns created through our app.
            </em>
          </p>
        </div>
      </Page>
      <Notification
        notification={notification}
        handleCloseNotification={handleCloseNotification}
      />
      <BottomNavigationMobile />
      <Snackbar
        open={userMessage.open}
        autoHideDuration={userMessage.timeout}
        onClose={handleUserMessageClose}
      >
        <Alert onClose={handleUserMessageClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Campaigns;
