import React, { useState, useEffect } from "react";
import "../css/login.css";
import logo from "../images/logo.svg";
import background from "../images/login.jpeg";
import image from "../images/login.svg";
import Button from "@material-ui/core/Button";

import { withRouter, Link } from "react-router-dom";
import SignUpWithFacebook from "../components/SignUpWithFacebook";
import SignUpWithEmailAndPassword from "../components/SignUpWithEmailAndPassword";

import ReactPixel from "react-facebook-pixel";

const SignUp = (props) => {
  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init("2517266624991480", options);
    ReactPixel.pageView();
  }, []);

  return (
    <div className="login">
      <div className="aside">
        <div className="aside-content">
          <h2>We Run Ads</h2>
          <p className="aside-text">
            Nothing sticks in customers' heads better than a story. Tell yours!
          </p>
        </div>
        <div
          className="aside-image"
          style={{ backgroundImage: "url(" + image + ")" }}
        ></div>
      </div>
      <div
        className="content"
        style={{ backgroundImage: "url(" + background + ")" }}
      >
        <div className="login-form">
          <div className="form-content">
            <img src={logo} alt="Zbang" className="logo" />
            <h1 className="title">
              Start your free trial
              <br />
              No credit card required.
            </h1>
            <p className="paragraph">
              Manage, Optimize and increase ROAS with our Catalogue Manager.
            </p>

            <div className="sign-in-methods">
              <SignUpWithFacebook title="Sign up with Facebook" />
              <div className="or">
                <span>or</span>
              </div>
              <SignUpWithEmailAndPassword />
            </div>

            <div className="legal">
              <p>
                By continuing, you are indicating that you accept our{" "}
                <Link to="/terms-of-service">Terms of Service</Link> and{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>. Free trial
                until 1st of May for all users. After that $99 for every Ad
                account you have campaigns on.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignUp);
