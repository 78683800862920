import React, { useState, useEffect, useRef } from "react";
import firebase from "../config/firebase";
import { withRouter } from "react-router-dom";

const ReportSelectedCampaign = (props) => {
  const { report, uid } = props;
  const [campaigns, setCampaigns] = useState([]);
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(true);
  const [isCampaignsEmpty, setIsCampaignsEmpty] = useState(true);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("campaigns")
      .where("uid", "==", uid)
      .onSnapshot(function (querySnapshot) {
        let tempCampaigns = [];
        let isEmpty = true;
        querySnapshot.forEach(function (doc) {
          isEmpty = false;
          tempCampaigns.push({ ...doc.data(), id: doc.id });
        });
        if (componentIsMounted.current) {
          setIsCampaignsLoading(false);
          setIsCampaignsEmpty(isEmpty);
          setCampaigns(tempCampaigns);
        }
      });
  }, []);

  return (
    <div
      className={
        report.object && report.object.type === "campaign"
          ? "report-object report-campaigns active"
          : "report-object report-campaigns"
      }
      onClick={() => {
        props.history.push("/campaigns");
      }}
    >
      <h3 className="title">
        {report.object && report.object.type === "campaign"
          ? report.object.campaign_fb_id
          : "Select Campaign"}
      </h3>
      <div className="subtitle">
        <span>{campaigns.length} available</span>
      </div>
    </div>
  );
};

export default withRouter(ReportSelectedCampaign);
