// eslint-disable-next-line
import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

export const theme = createMuiTheme({
  typography: {
    fontFamily: `"SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif`,
  },
  palette: {
    type: "dark",
    primary: { 500: "#34c759", contrastText: "#fff" },
    secondary: { main: "#34c759" },
    background: {
      paper: "#ffffff",
      drawer: "#ffffff",
      content: "#f0f6ef",
      drawerToolbar: "#34c759",
    },
    borderColor: { main: "#eee", light: "#fafafa", dark: "#ddd" },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
    toolbarMobile: {
      minHeight: 60,
    },
  },
  drawer: {
    width: "34vw",
    mobile: {
      width: "80vw",
    },
  },
});
