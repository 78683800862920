import React, { useState, useEffect } from "react";
import "../css/login.css";
import logo from "../images/logo.svg";
import background from "../images/login.jpeg";
import image from "../images/login.svg";

import { withRouter, Link } from "react-router-dom";
import LoginWithFacebook from "../components/LoginWithFacebook";
import LoginWithEmailAndPassword from "../components/LoginWithEmailAndPassword";
import Notification from "../components/Notification";

const Login = (props) => {
  const [notification, setNotification] = useState(null);

  const handleCloseNotification = () => {
    setNotification(null);
  };

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.notification
    ) {
      setNotification(props.location.state.notification);
      let timeout = setTimeout(() => {
        const state = { ...props.history.location.state };
        delete state.notification;
        props.history.replace({ ...props.history.location, state });
      }, props.location.state.notification.timeout);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="login">
        <div className="aside">
          <div className="aside-content">
            <h2>We Run Ads</h2>
            <p className="aside-text">
              Nothing sticks in customers' heads better than a story. Tell
              yours!
            </p>
          </div>
          <div
            className="aside-image"
            style={{ backgroundImage: "url(" + image + ")" }}
          ></div>
        </div>
        <div
          className="content"
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <div className="login-form">
            <div className="form-content">
              <img src={logo} alt="Zbang" className="logo" />
              <h1 className="title">
                Start your free trial
                <br />
                No credit card required.
              </h1>
              <p className="paragraph">
                Manage, Optimize and increase ROAS with our Catalogue Manager.
              </p>

              <div className="sign-in-methods">
                <LoginWithFacebook />
                <div className="or">
                  <span>or</span>
                </div>
                <LoginWithEmailAndPassword />
              </div>

              <div className="legal">
                <p>
                  By continuing, you are indicating that you accept our{" "}
                  <Link to="/terms-of-service">Terms of Service</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>. Free trial
                  until 1st of May for all users. After that $99 for every Ad
                  account you have campaigns on.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notification
        notification={notification}
        handleCloseNotification={handleCloseNotification}
      />
    </React.Fragment>
  );
};

export default withRouter(Login);
