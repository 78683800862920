import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import CircularProgress from "@material-ui/core/CircularProgress";

const Bar = (props) => {
  const {
    title,
    handleDrawerToggle,
    isTransparent,
    actions,
    isLoading,
  } = props;
  return (
    <AppBar position="fixed" className="app-bar">
      <Toolbar className="toolbar">
        <div className="title-icon">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className="menu-button"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className="page-title">
            <span>
              {title}
              {isLoading ? (
                <CircularProgress style={{ marginLeft: "10px" }} size={18} />
              ) : null}
            </span>
          </Typography>
        </div>
        <div className="actions">{actions}</div>
      </Toolbar>
    </AppBar>
  );
};

export default Bar;
