import React, { useState, useRef, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link, withRouter } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import ResourceMobileFilter from "./ResourceMobileFilter";
import firebase from "../config/firebase";

const CampaignList = (props) => {
  const {
    campaigns,
    handlePagination,
    wasPaginated,
    startAfterDoc,
    currentPage,
    limit,
    setLimit,
    isLoading,
    isEmpty,
    isLastPage,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    profile,
    handleCreateCampaign,
    isCampaignCreating,
  } = props;

  const [open, setOpen] = useState(false);
  const [scopedIsLoading, setScopedIsLoading] = useState(false);
  const [clickedCampaign, setClickedCampaign] = useState(null);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  if (isLoading && campaigns.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="loading-component">
            <p>Gathering data</p> <CircularProgress size={20} />
          </div>
        </div>
      </div>
    );
  }

  const handleCampaignSelect = async (event, campaign) => {
    if (componentIsMounted.current) {
      setScopedIsLoading(true);
      setClickedCampaign(campaign.id);
    }
    const functions = firebase.functions();
    const get_report_products = functions.httpsCallable("get_report_products");
    const get_report_metrics = functions.httpsCallable("get_report_metrics");
    const get_report_charts = functions.httpsCallable("get_report_charts");
    try {
      await firebase.firestore().collection("reports").doc(profile.uid).set(
        {
          is_metrics_loading: true,
          is_charts_loading: true,
          is_products_loading: true,
          date_preset: "last_7d",
          cache: [],
          selected_product: null,
          updated_at: new Date(),
          uid: profile.uid,
        },
        { merge: true }
      );
      await get_report_products({
        object: {
          ...campaign,
          type: "campaign",
          edge: campaign.campaign_fb_id,
          timezone: profile.selected_ad_account.timezone,
        },
        date_preset: "last_7d",
        after: null,
      });
      await get_report_metrics({
        object: {
          ...campaign,
          type: "campaign",
          edge: campaign.campaign_fb_id,
          timezone: profile.selected_ad_account.timezone,
        },
        date_preset: "last_7d",
      });
      await get_report_charts({
        object: {
          ...campaign,
          type: "campaign",
          edge: campaign.campaign_fb_id,
          timezone: profile.selected_ad_account.timezone,
        },
        date_preset: "last_7d",
      });
      if (componentIsMounted.current) {
        setScopedIsLoading(false);
        setClickedCampaign(null);
      }
      props.history.push("/dashboard");
    } catch (error) {
      if (componentIsMounted.current) {
        setScopedIsLoading(false);
        setClickedCampaign(null);
      }
      console.log(error.toString());
    }
  };

  /////TO DO: Create campaign popup (2 options, catalog sales or retarget)
  if (!isLoading && isEmpty && campaigns.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="empty-component">
            <p>You didn't create any campaigns*</p>
          </div>
          <div className="campaign-actions">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateCampaign}
              disabled={isCampaignCreating}
              endIcon={
                isCampaignCreating ? <CircularProgress size={15} /> : null
              }
            >
              Create Campaign
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="resource-table">
        {campaigns.length > 0 ? (
          <Toolbar className="action-bar">
            <div className="item">
              <span>Per page</span>
              <Select
                id="per-page"
                value={limit}
                onChange={(event) => {
                  setLimit(event.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </div>
            <div className="item">
              <span>Order by</span>
              <Select
                id="order-by"
                value={orderBy}
                onChange={(event) => {
                  setOrderBy(event.target.value);
                }}
              >
                <MenuItem value="created_at">Date</MenuItem>
                <MenuItem value="objective">Type</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </Select>
            </div>
            <div className="item">
              <span>Direction</span>
              <Select
                id="order-direction"
                value={orderDirection}
                onChange={(event) => {
                  setOrderDirection(event.target.value);
                }}
              >
                <MenuItem value="desc">Descending</MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
              </Select>
            </div>
          </Toolbar>
        ) : null}
        <Toolbar className="head">
          <span>Title</span>
          <span>Bid</span>
          <span>Budget</span>
          <span>Status</span>
        </Toolbar>
        <Toolbar className="mobile-resource-filter">
          <Button
            type="text"
            color="primary"
            startIcon={<FilterListIcon />}
            onClick={() => {
              setOpen(true);
            }}
          >
            Filter
          </Button>
        </Toolbar>

        <ul className="campaign-list">
          {campaigns.map((campaign) => {
            return (
              <li key={campaign.id}>
                <Button
                  className="campaign-title"
                  onClick={(event) => {
                    handleCampaignSelect(event, campaign);
                  }}
                  disabled={scopedIsLoading}
                >
                  {campaign.campaign_fb_id}
                  <span className="view-campaign-report">
                    View Report{" "}
                    {scopedIsLoading &&
                    clickedCampaign &&
                    clickedCampaign === campaign.id ? (
                      <span className="report-is-loading">
                        <CircularProgress size={15} />
                      </span>
                    ) : null}
                  </span>
                </Button>
                <span>
                  <span className="label">Bid:</span>
                  <span className="amount">
                    {campaign.ad_set.bid_amount / 100}{" "}
                    <span className="currency-notice">
                      {campaign.ad_account.currency}
                    </span>
                  </span>
                </span>
                <span>
                  <span className="label">Budget:</span>
                  <span className="amount">
                    {campaign.ad_set.daily_budget / 100}{" "}
                    <span className="currency-notice">
                      {campaign.ad_account.currency}
                    </span>
                  </span>
                </span>
                <span>
                  <span
                    className={
                      campaign.status === "ACTIVE"
                        ? "status active"
                        : "status paused"
                    }
                  >
                    <span>{campaign.status}</span>
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      className="edit-campaign-button"
                      onClick={() => {
                        props.history.push(`/campaigns/${campaign.id}`);
                      }}
                    >
                      Edit
                    </Button>
                  </span>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      {isLastPage && wasPaginated ? (
        <div className="pagination">
          <p style={{ margin: 0 }}>You've reached the end.</p>
        </div>
      ) : null}
      {isLastPage ? null : (
        <div className="pagination">
          <Button
            className="pagination-load-more"
            variant="outlined"
            onClick={handlePagination}
            endIcon={isLoading ? <CircularProgress size={18} /> : null}
          >
            Load more
          </Button>
        </div>
      )}
      <ResourceMobileFilter
        resources={campaigns}
        limit={limit}
        setLimit={setLimit}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        open={open}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
};

export default withRouter(CampaignList);
