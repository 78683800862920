import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import facebook from "../images/bflogo.png";
import Alert from "@material-ui/lab/Alert";
import firebase from "../config/firebase";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPixel from "react-facebook-pixel";

const LoginWithFacebook = (props) => {
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "",
    timeout: 12000,
    open: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  let provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope("public_profile");
  provider.addScope("email");
  provider.addScope("ads_management");
  provider.addScope("business_management");
  provider.addScope("ads_read");
  provider.addScope("catalog_management");
  provider.addScope("attribution_read");
  provider.addScope("pages_show_list");
  provider.addScope("pages_read_engagement");
  provider.setCustomParameters({
    display: "popup",
  });

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init("2517266624991480", options);
  }, []);

  const functions = firebase.functions();

  const handleClose = () => {
    if (componentIsMounted.current) {
      setUserMessage({ message: "", severity: "", open: false });
    }
  };

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleFacebookLogin = async (event) => {
    event.preventDefault();
    const firestore = firebase.firestore();

    let credential, access_token, user, profile, info;

    if (componentIsMounted.current) {
      setIsLoading(true);
    }

    try {
      const result = await firebase.auth().signInWithPopup(provider);
      credential = result.credential;
      access_token = credential.accessToken;
      user = result.user;
      info = result.additionalUserInfo;
      profile = result.additionalUserInfo.profile;

      await firestore
        .collection("users")
        .doc(user.uid)
        .set(
          {
            facebook_email: profile.email ? profile.email : null,
            first_name: profile.first_name,
            last_name: profile.last_name,
            full_name: profile.name,
            fbuid: profile.id,
            picture: profile.picture.data,
            granted_scopes: profile.granted_scopes,
            is_facebook_connected: true,
            is_loading: false,
            is_empty: false,
            uid: user.uid,
          },
          { merge: true }
        );

      await firestore.collection("tokens").doc(user.uid).set({
        access_token: access_token,
        fbuid: profile.id,
      });

      if (info.isNewUser) {
        let request = functions.httpsCallable("get_businesses");
        await request();
        ReactPixel.track("Start Trial");
        props.history.push("/dashboard", {
          notification: {
            message: `Your are now signed in as ${profile.name}!`,
            severity: "success",
            timeout: 12000,
            status: true,
          },
        });
      } else {
        props.history.push("/dashboard", {
          notification: {
            message: `Your are now signed in as ${profile.name}!`,
            severity: "success",
            timeout: 12000,
            status: true,
          },
        });
      }
    } catch (error) {
      if (componentIsMounted.current) {
        setUserMessage({
          message: error.message,
          severity: "error",
          timeout: 12000,
          open: true,
        });
        setIsLoading(false);
      }
      await firestore.collection("errors").add({
        performed_action: "profile_update_on_login",
        performed_at: new Date(),
        error: error.toString(),
      });
    }
  };
  return (
    <React.Fragment>
      <Button
        className="login-button login-facebook"
        type="button"
        onClick={handleFacebookLogin}
        endIcon={isLoading ? <CircularProgress size={18} /> : null}
      >
        <img src={facebook} alt="Facebook" width={30} height={30} />
        Sign up with Facebook
      </Button>
      <Snackbar
        open={userMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default withRouter(LoginWithFacebook);
