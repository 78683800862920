import React, { useState, useEffect, useContext } from "react";

import { AuthContext } from "../config/AuthProvider";
import { Redirect } from "react-router-dom";
import Menu from "../components/Menu";
import Notification from "../components/Notification";
import Bar from "../components/Bar";
import Page from "../components/Page";

import SelectBusiness from "../components/SelectBusiness";
import SelectAdAccount from "../components/SelectAdAccount";
import BusinessAvatar from "../components/BusinessAvatar";
import AdAccountAvatar from "../components/AdAccountAvatar";
import CheckmarkIcon from "../components/CheckmarkIcon";
import BottomNavigationMobile from "../components/BottomNavigationMobile";
import AdAccountTimezone from "../components/AdAccountTimezone";

const Settings = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const [highlight, setHighlight] = useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  useEffect(() => {
    console.log("Props changed");
    let notificationTimeout, highlightTimeout;
    if (
      props.location &&
      props.location.state &&
      props.location.state.notification
    ) {
      setNotification(props.location.state.notification);
      notificationTimeout = setTimeout(() => {
        const state = { ...props.history.location.state };
        delete state.notification;
        props.history.replace({ ...props.history.location, state });
      }, props.location.state.notification.timeout);
    }
    if (
      props.location &&
      props.location.state &&
      props.location.state.highlight
    ) {
      setHighlight(props.location.state.highlight);
    }

    return () => {
      if (
        props.location &&
        props.location.state &&
        props.location.state.notification
      ) {
        clearTimeout(notificationTimeout);
      }
    };
  }, [props]);

  if (!profile.is_facebook_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-facebook" />
      </React.Fragment>
    );
  }

  if (!profile.is_email_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-email" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Menu
        profile={profile}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        window={window}
        claims={currentUser.claims}
      />
      <Bar
        title={
          !profile.selected_business
            ? "Select Business Manager"
            : !profile.selected_ad_account
            ? "Select Ad Account"
            : "Settings"
        }
        actions={null}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Page>
        <div className="page-no-sidebar">
          {!profile.selected_business ? (
            <React.Fragment>
              <SelectBusiness profile={profile} />
            </React.Fragment>
          ) : null}

          {profile.selected_business ? (
            <div
              className={highlight === "business" ? "card highlighted" : "card"}
            >
              <div className="card-header">
                <div>
                  <h4 className="title">Business Manager</h4>
                </div>
              </div>
              <div className="card-body">
                <div className="selected">
                  <BusinessAvatar
                    uid={profile.uid}
                    business={profile.selected_business}
                  />
                  <CheckmarkIcon />
                </div>
              </div>
            </div>
          ) : null}

          {profile.selected_business && !profile.selected_ad_account ? (
            <React.Fragment>
              <SelectAdAccount profile={profile} />
            </React.Fragment>
          ) : null}

          {profile.selected_ad_account ? (
            <React.Fragment>
              <div
                className={
                  highlight === "ad-account" ? "card highlighted" : "card"
                }
              >
                <div className="card-header">
                  <div>
                    <h4 className="title">Ad Account</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div className="selected">
                    <AdAccountAvatar
                      uid={profile.uid}
                      ad_account={profile.selected_ad_account}
                    />
                    <CheckmarkIcon />
                  </div>
                </div>
              </div>
              <AdAccountTimezone />
            </React.Fragment>
          ) : null}
        </div>
      </Page>
      <Notification
        notification={notification}
        handleCloseNotification={handleCloseNotification}
      />
      <BottomNavigationMobile />
    </React.Fragment>
  );
};

export default Settings;
