import React, { useState } from "react";
import "../css/login.css";
import logo from "../images/logo.svg";
import background from "../images/login.jpeg";
import image from "../images/login.svg";
import Button from "@material-ui/core/Button";

import { withRouter, Link } from "react-router-dom";
import PasswordResetForm from "../components/PasswordResetForm";

const PasswordReset = (props) => {
  return (
    <div className="login">
      <div className="aside">
        <div className="aside-content">
          <h2>We Run Ads</h2>
          <p className="aside-text">
            Nothing sticks in customers' heads better than a story. Tell yours!
          </p>
        </div>
        <div
          className="aside-image"
          style={{ backgroundImage: "url(" + image + ")" }}
        ></div>
      </div>
      <div
        className="content"
        style={{ backgroundImage: "url(" + background + ")" }}
      >
        <div className="login-form">
          <div className="form-content">
            <img src={logo} alt="Zbang" className="logo" />
            <h1 className="title">Forgot your password?</h1>
            <p className="paragraph">
              Don't worry! Just fill in your email and we'll send you a link to
              reset your password.
            </p>

            <div className="sign-in-methods">
              <PasswordResetForm />
            </div>

            <div className="legal">
              <p>
                By continuing, you are indicating that you accept our{" "}
                <Link to="/terms-of-service">Terms of Service</Link> and{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PasswordReset);
