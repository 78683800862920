import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const ResourceFilter = (props) => {
  const {
    resources,
    limit,
    setLimit,
    orderBy,
    setOrderBy,
    open,
    setOpen,
    orderDirection,
    setOrderDirection,
  } = props;
  const fullScreen = useMediaQuery("(max-width:1099px)");

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="CreateCampaignModal"
        className="dialog"
      >
        <DialogTitle className="dialog-title">
          <div className="title-content">Filter</div>
          <Fab size="small" onClick={handleClose}>
            <CloseIcon />
          </Fab>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <div className="resource-table">
            {resources.length > 0 ? (
              <Toolbar className="action-bar">
                <div className="item">
                  <span>Per page</span>
                  <Select
                    id="per-page"
                    value={limit}
                    onChange={(event) => {
                      setLimit(event.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </div>
                <div className="item">
                  <span>Order by</span>
                  <Select
                    id="order-by"
                    value={orderBy}
                    onChange={(event) => {
                      setOrderBy(event.target.value);
                    }}
                  >
                    <MenuItem value="created_at">Date</MenuItem>
                    <MenuItem value="objective">Type</MenuItem>
                    <MenuItem value="status">Status</MenuItem>
                  </Select>
                </div>
                <div className="item">
                  <span>Direction</span>
                  <Select
                    id="order-direction"
                    value={orderDirection}
                    onChange={(event) => {
                      setOrderDirection(event.target.value);
                    }}
                  >
                    <MenuItem value="desc">Descending</MenuItem>
                    <MenuItem value="asc">Ascending</MenuItem>
                  </Select>
                </div>
              </Toolbar>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <span></span>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleClose}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResourceFilter;
