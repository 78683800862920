import React from "react";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DatePreset from "./DatePreset";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import ReportMetrics from "./ReportMetrics";
import ReportCharts from "./ReportCharts";
import firebase from "../config/firebase";

const ReportSelectedMobile = (props) => {
  const {
    open,
    setOpen,
    profile,
    data,
    metrics,
    preset,
    handlePresetChange,
    isLoading,
    report,
    isMobile,
  } = props;
  const fullScreen = useMediaQuery("(max-width:1099px)");

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="dialog"
      >
        <DialogTitle className="dialog-title">
          <div className="title-content">
            {report.selected_product
              ? report.selected_product.id.split(",")[1]
              : report && report.object && report.object.type === "ad_account"
              ? profile.selected_ad_account.name
              : report && report.object && report.object.campaign_fb_id
              ? report.object.campaign_fb_id
              : null}
            <br />
            {report.selected_product ? (
              <small>ID: {report.selected_product.id.split(",")[0]}</small>
            ) : report &&
              report.object &&
              report.object.type === "ad_account" ? (
              <small>{report.object.id}</small>
            ) : null}
          </div>
          <Fab size="small" onClick={handleClose}>
            <CloseIcon />
          </Fab>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <DatePreset
            preset={
              report.selected_product && report.selected_product.date_preset
                ? report.selected_product.date_preset
                : preset
            }
            isLoading={report.is_products_loading}
            handlePresetChange={handlePresetChange}
          />
          <ReportMetrics
            classes="mobile"
            metrics={metrics}
            isLoading={report.is_metrics_loading}
            profile={profile}
          />
          <ReportCharts
            metrics={report.metrics}
            preset={
              report.selected_product && report.selected_product.date_preset
                ? report.selected_product.date_preset
                : preset
            }
            data={data}
            profile={profile}
            isLoading={report.is_charts_loading}
            isMobile={isMobile}
            report={report}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReportSelectedMobile;
