import React, { useState, useEffect, useRef } from "react";
import firebase from "../config/firebase";
import Button from "@material-ui/core/BUtton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import _ from "lodash";
import { withRouter } from "react-router-dom";

const SelectAdAccount = (props) => {
  const { profile } = props;
  const [selectedAdAccount, setSelectedAdAccount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "",
    open: false,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (profile.selected_ad_account) {
      setSelectedAdAccount(profile.selected_ad_account);
    }
  }, [profile]);

  const handleClose = () => {
    if (componentIsMounted.current) {
      setUserMessage({ message: "", severity: "", open: false });
    }
  };

  const handleSelectedAdAccount = (event) => {
    const selected_ad_account_id = event.target.value;
    const ad_account = _.find(profile.ad_accounts, function (o) {
      return o.account_id == selected_ad_account_id;
    });

    if (componentIsMounted.current) {
      setSelectedAdAccount(ad_account);
    }
  };

  const handleUpdateSelectedAdAccount = () => {
    setIsLoading(true);

    if (selectedAdAccount) {
      const functions = firebase.functions();
      const request = functions.httpsCallable("update_selected_ad_account");
      request({ ad_account: selectedAdAccount })
        .then((result) => {
          const payload = result.data;
          setIsLoading(false);
          props.history.push("/dashboard", {
            notification: {
              message: `Your selected Ad Account has been updated!`,
              severity: "success",
              timeout: 12000,
              status: true,
            },
          });
          if (componentIsMounted.current) {
            // setUserMessage({
            //   message: "Your selected Ad Account has been updated!",
            //   severity: "success",
            //   open: true,
            // });
          }
        })
        .catch((error) => {
          if (componentIsMounted.current) {
            setUserMessage({
              message: error.message,
              severity: "error",
              open: true,
            });
            setIsLoading(false);
          }
        });
    } else {
      if (componentIsMounted.current) {
        setUserMessage({
          message: "Please select an Ad Account",
          severity: "warning",
          open: true,
        });
      }
    }
  };

  console.log("Alex", profile);

  return (
    <React.Fragment>
      <div>
        <div className="card">
          <div className="card-body">
            <React.Fragment>
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="selectedAdAccount"
                    value={
                      selectedAdAccount && selectedAdAccount.account_id
                        ? selectedAdAccount.account_id
                        : null
                    }
                    onChange={handleSelectedAdAccount}
                  >
                    {profile.ad_accounts.map((ad_account) => {
                      let statusName = "";
                      if (ad_account.account_status === 2) {
                        statusName = " (Disabled)";
                      }
                      if (ad_account.account_status === 3) {
                        statusName = " (Unsettled)";
                      }
                      if (ad_account.account_status === 7) {
                        statusName = " (Pending Risk Review)";
                      }
                      if (ad_account.account_status === 8) {
                        statusName = " (Pending Settlement)";
                      }
                      if (ad_account.account_status === 9) {
                        statusName = " (In Grace Period)";
                      }
                      if (ad_account.account_status === 100) {
                        statusName = " (Pending Closure)";
                      }
                      if (ad_account.account_status === 101) {
                        statusName = " (Closed)";
                      }
                      if (ad_account.account_status === 201) {
                        statusName = " (Any Active)";
                      }
                      if (ad_account.account_status === 202) {
                        statusName = " (Any Closed)";
                      }
                      return (
                        <FormControlLabel
                          key={ad_account.account_id}
                          value={ad_account.account_id}
                          control={<Radio />}
                          label={`${ad_account.name} ${statusName}`}
                          disabled={
                            ad_account.account_status === 2 ||
                            ad_account.account_status === 101 ||
                            ad_account.account_status === 3 ||
                            ad_account.account_status === 7 ||
                            ad_account.account_status === 8 ||
                            ad_account.account_status === 100 ||
                            ad_account.account_status === 202
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
              {!selectedAdAccount ? (
                <p className="text-warning">Please selecte an Ad Account</p>
              ) : (
                <br />
              )}

              <Button
                onClick={handleUpdateSelectedAdAccount}
                variant="contained"
                color="primary"
                disabled={!selectedAdAccount}
                endIcon={isLoading ? <CircularProgress size={18} /> : null}
              >
                Select Ad Account
              </Button>
            </React.Fragment>
          </div>
        </div>
      </div>
      <Snackbar
        open={userMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default withRouter(SelectAdAccount);
