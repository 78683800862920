import React from "react";
import { withRouter } from "react-router-dom";

const ReportSelectedProduct = (props) => {
  const { product, uid } = props;

  return (
    <div className={"report-object active report-selected-product"}>
      <h3 className="title" style={{ fontSize: "16px" }}>
        {product.id.split(",")[1]}
      </h3>
      <div className="subtitle">
        <span>ID: {product.id.split(",")[0]}</span>
      </div>
    </div>
  );
};

export default withRouter(ReportSelectedProduct);
