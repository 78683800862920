import React, { useState, useRef, useEffect, useContext } from "react";
import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import validator from "email-validator";
import firebase from "../config/firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { withRouter, Link } from "react-router-dom";
import zxcvbn from "zxcvbn";
import logo from "../images/logo.svg";
import background from "../images/login.jpeg";
import "../css/login.css";
import { AuthContext } from "../config/AuthProvider";
import LoginWithFacebook from "../components/LoginWithFacebook";
import CheckIcon from "@material-ui/icons/Check";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import { CircularProgress } from "@material-ui/core";

const LoginWithEmailAndPassword = (props) => {
  const { profile, currentUser } = useContext(AuthContext);

  const { history } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userMessage, setUserMessage] = useState({ message: "", type: "" });
  const [open, setOpen] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(null);
  const [
    requiresRecentAuthentication,
    setRequiresRecentAuthentication,
  ] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    setPasswordValidator(zxcvbn(event.target.value));
  };
  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (componentIsMounted.current) {
      setOpen(false);
      setIsLoading(true);
    }

    if (password.length > 5 && password === confirmPassword) {
      const firestore = firebase.firestore();
      let credential = firebase.auth.EmailAuthProvider.credential(
        profile.facebook_email || email,
        password
      );
      new Promise((resolve, reject) => {
        firebase
          .auth()
          .currentUser.linkWithCredential(credential)
          .then((userCredential) => {
            const user = userCredential.user;

            return firestore
              .collection("users")
              .doc(user.uid)
              .set(
                {
                  email: profile.facebook_email || email,
                  is_email_connected: true,
                  is_loading: false,
                  is_empty: false,
                },
                { merge: true }
              );
          })
          .then(() => {
            if (componentIsMounted.current) {
              setIsLoading(false);
            }
            history.push("/dashboard", {
              notification: {
                message: `Your password has been set for ${
                  profile.facebook_email || email
                }!`,
                severity: "success",
                timeout: 12000,
                status: true,
              },
            });
            resolve(profile.facebook_email);
          })
          .catch((error) => {
            console.log("Error", error);
            if (componentIsMounted.current) {
              setUserMessage({
                message: error.message,
                type: "error",
              });
              setOpen(true);
              setIsLoading(false);
              if (error.code === "auth/requires-recent-login") {
                setRequiresRecentAuthentication(true);
              }
            }
            reject(error);
          });
      });
    } else {
      if (password !== confirmPassword) {
        if (componentIsMounted.current) {
          setUserMessage({
            message: "Passwords are not the same!",
            type: "error",
          });
          setIsLoading(false);
          setOpen(true);
        }
      } else {
        if (componentIsMounted.current) {
          setUserMessage({
            message:
              "Password should be at least 6 characters. Please try again!",
            type: "error",
          });
          setIsLoading(false);
          setOpen(true);
        }
      }
    }
  };

  const handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {})
      .catch((error) => {
        setUserMessage({
          message:
            "Password should be at least 6 characters. Please try again!",
          type: "error",
        });
        setOpen(true);
      });
  };

  if (requiresRecentAuthentication) {
    return (
      <React.Fragment>
        <div className="login">
          <div
            className="content"
            style={{ backgroundImage: "url(" + background + ")" }}
          >
            <div className="login-form">
              <div className="form-content">
                <img src={logo} alt="Zbang" className="logo" />
                <h1 className="title">
                  Log in again before retrying this request.
                </h1>
                <p className="paragraph">
                  This operation is sensitive and requires recent authentication
                  (Too much time has passed since your last login).
                </p>

                <div className="sign-in-methods">
                  <LoginWithFacebook />
                </div>

                <div className="legal">
                  <p>
                    By continuing, you are indicating that you accept our{" "}
                    <Link to="/terms-of-service">Terms of Service</Link> and{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (profile.is_email_connected) {
    return (
      <React.Fragment>
        <div className="login">
          <div
            className="content"
            style={{ backgroundImage: "url(" + background + ")" }}
          >
            <div className="login-form">
              <div className="form-content">
                <h1 className="title">
                  <span className="connected-checkmark">
                    <CheckIcon />
                  </span>
                  Password set
                </h1>
                <p className="paragraph">
                  Your email is {profile.email}
                  <br />
                  and your account is protected by the password you set.
                </p>
              </div>
              <Button
                onClick={() => {
                  props.history.push("/dashboard");
                }}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="login">
        <div
          className="content"
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <div className="login-form">
            <div className="form-content">
              <img src={logo} alt="Zbang" className="logo" />
              <h1 className="title">...one more step</h1>
              {profile.facebook_email ? (
                <p className="paragraph">
                  Create a password for {profile.facebook_email}
                </p>
              ) : (
                <p className="paragraph">
                  Please enter your email and password.
                </p>
              )}

              <div className="sign-in-methods">
                <form onSubmit={handleSubmit}>
                  {!profile.facebook_email ? (
                    <FormControl
                      className="form-control"
                      variant="outlined"
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={email}
                        onChange={handleChangeEmail}
                        labelWidth={75}
                      />
                    </FormControl>
                  ) : null}
                  <FormControl
                    className="form-control"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handleChangePassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={75}
                    />
                  </FormControl>

                  <FormControl
                    className="form-control"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-confirm-password">
                      Confirm password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-confirm-password"
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={handleChangeConfirmPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={135}
                    />
                  </FormControl>

                  {passwordValidator &&
                  passwordValidator.feedback &&
                  passwordValidator.feedback.warning ? (
                    <Alert severity="warning">
                      {passwordValidator.feedback.warning}
                    </Alert>
                  ) : null}

                  <Button
                    className="login-button login-email"
                    type="submit"
                    endIcon={isLoading ? <CircularProgress size={18} /> : null}
                  >
                    Create Password
                  </Button>

                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity={userMessage.type}>
                      {userMessage.message}
                    </Alert>
                  </Snackbar>
                </form>
                <Button onClick={handleSignout}>Sign out</Button>
              </div>

              <div className="legal">
                <p>
                  By continuing, you are indicating that you accept our{" "}
                  <Link to="/terms-of-service">Terms of Service</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(LoginWithEmailAndPassword);
