import React, { useState, useRef, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import firebase from "../config/firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const AdAccountAvatar = (props) => {
  const { ad_account, uid } = props;
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "",
    open: false,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleChange = async () => {
    const firestore = firebase.firestore();
    try {
      await firestore.collection("users").doc(uid).update({
        selected_ad_account: null,
      });
    } catch (error) {
      if (componentIsMounted.current) {
        setUserMessage({
          message: error.message,
          severity: "error",
          timeout: 12000,
          open: true,
        });
      }
    }
  };

  const handleClose = () => {
    if (componentIsMounted.current) {
      setUserMessage({ message: "", severity: "", open: false });
    }
  };

  if (ad_account) {
    return (
      <React.Fragment>
        <div className="selected-avatar">
          <Avatar
            variant="rounded"
            className="avatar"
            alt={ad_account.name}
            src={ad_account.picture_url}
          />
          <span>
            <span>
              {ad_account.name} <Button onClick={handleChange}>(change)</Button>
            </span>
          </span>
        </div>
        <Snackbar
          open={userMessage.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={userMessage.severity}>
            {userMessage.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }

  return null;
};

export default AdAccountAvatar;
