import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import Button from "@material-ui/core/Button";
import { withRouter, Link } from "react-router-dom";
import firebase from "../config/firebase";
import CheckIcon from "@material-ui/icons/Check";

const AccountType = (props) => {
  const { claims, profile } = props;
  const {
    is_admin,
    access_level,
    premium,
    free_trial,
    free_trial_started_on,
  } = claims;

  const [subscription, setSubscription] = useState({
    is_loading: true,
    is_empty: true,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();
    firestore
      .collection("subscriptions")
      .doc(profile.uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          if (componentIsMounted.current) {
            setSubscription({
              ...doc.data(),
              id: doc.id,
              is_loading: false,
              is_empty: false,
            });
          }
        } else {
          if (componentIsMounted.current) {
            setSubscription({ is_loading: false, is_empty: true });
          }
        }
      });
  }, []);

  if (is_admin) {
    return null;
  }
  if (
    !subscription.is_loading &&
    !subscription.is_empty &&
    subscription.status === "active"
  ) {
    return (
      <div className="account-type account-type-premium">
        <div className="subscription-status">
          <div className={`icon icon-${subscription.status}`}>
            <CheckIcon />
          </div>
          <span>
            {`Subscription ${subscription.status}`}
            <br />
            <small>
              {dayjs
                .unix(subscription.current_period_end)
                .format("MMMM D, YYYY")}
            </small>
            <Button
              onClick={() => {
                props.history.push("/settings/account");
              }}
              variant="contained"
              color="primary"
            >
              My account
            </Button>
          </span>
        </div>
      </div>
    );
  }
  if (free_trial && !subscription.is_loading && subscription.is_empty) {
    return (
      <div className="account-type account-type-legacy">
        <p>
          Your free trial ends on{" "}
          {dayjs(free_trial_started_on)
            .add(14, "days")
            .format("dddd, MMMM D, YYYY")}
          . Upgrade to avoid loosing access.
        </p>
        <Button
          className="upgrade"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            props.history.push("/settings/account");
          }}
        >
          Upgrade
        </Button>
      </div>
    );
  }
  if (!subscription.is_loading && subscription.is_empty) {
    return (
      <div className="account-type account-type-legacy">
        <p>Upgrade to keep your campaigns up to date</p>
        <Button
          className="upgrade"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            props.history.push("/settings/account");
          }}
        >
          Upgrade
        </Button>
      </div>
    );
  }

  if (
    !subscription.is_loading &&
    !subscription.is_empty &&
    subscription.status === "canceled"
  ) {
    return (
      <div className="account-type account-type-legacy">
        <p>Upgrade to keep your campaigns up to date</p>
        <Button
          className="upgrade"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            props.history.push("/settings/account");
          }}
        >
          Upgrade
        </Button>
      </div>
    );
  }

  return null;
};

export default withRouter(AccountType);
