import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../config/AuthProvider";
import { Redirect, withRouter } from "react-router-dom";
import Menu from "../components/Menu";
import Bar from "../components/Bar";
import Page from "../components/Page";
import BottomNavigationMobile from "../components/BottomNavigationMobile";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import firebase from "../config/firebase";
import { useStripe } from "@stripe/react-stripe-js";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import dayjs from "dayjs";
import TextField from "@material-ui/core/TextField";

const Plan = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const { menuWindow } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState("");

  const stripe = useStripe();

  const [subscription, setSubscription] = useState({
    is_loading: true,
    is_empty: true,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();
    firestore
      .collection("subscriptions")
      .doc(profile.uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          if (componentIsMounted.current) {
            setSubscription({
              ...doc.data(),
              id: doc.id,
              is_loading: false,
              is_empty: false,
            });
          }
        } else {
          if (componentIsMounted.current) {
            setSubscription({ is_loading: false, is_empty: true });
          }
        }
      });
  }, []);

  const handleDiscountChange = (event) => {
    setDiscount(event.target.value);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleManageSubscriptions = (event) => {
    event.preventDefault();
    if (componentIsMounted.current) {
      setIsLoading(true);
    }
    const functions = firebase.functions();
    const customer_portal = functions.httpsCallable("customer_portal");
    customer_portal()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
        window.location.href = data.url;
      })
      .catch(function (error) {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
        console.error("Error:", error);
      });
  };

  const handleCheckoutSession = (event) => {
    event.preventDefault();
    if (componentIsMounted.current) {
      setIsLoading(true);
    }
    const functions = firebase.functions();
    const create_checkout_session = functions.httpsCallable(
      "create_checkout_session"
    );
    create_checkout_session({
      promotion_code: discount,
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
        if (data.sessionId) {
          stripe.redirectToCheckout({
            sessionId: data.sessionId,
          });
        } else {
          throw "Something went wrong with your request. Please come back later!";
        }
      })
      .catch(function (error) {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
        console.error("Error:", error);
      });
  };

  if (!profile.is_facebook_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-facebook" />
      </React.Fragment>
    );
  }

  if (!profile.is_email_connected) {
    return (
      <React.Fragment>
        <Redirect to="/connect-email" />
      </React.Fragment>
    );
  }

  console.log("Subscription -> Plan.js", subscription);

  if (subscription.is_loading || !window) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={menuWindow}
          claims={currentUser.claims}
        />
        <Bar
          title={"Upgrade Account"}
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <div className="page-no-sidebar">
            <div className="loading-component">
              <p>Gathering data</p> <CircularProgress size={20} />
            </div>
          </div>
        </Page>

        <BottomNavigationMobile />
      </React.Fragment>
    );
  }

  if (
    !subscription.is_loading &&
    !subscription.is_empty &&
    subscription.status !== "canceled"
  ) {
    return (
      <React.Fragment>
        <Menu
          profile={profile}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          window={menuWindow}
          claims={currentUser.claims}
        />
        <Bar
          title={`Subscription ${subscription.status}`}
          actions={null}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Page>
          <div className="page-no-sidebar">
            <div className="subscriptions">
              <div
                className={`subscription subscription-${subscription.status}`}
              >
                <div className={`icon icon-${subscription.status}`}>
                  {subscription.status === "active" ? (
                    <CheckIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </div>
                <h2>{`Subscription ${subscription.status}`}</h2>
                <p>$99/month</p>
                {subscription.current_period_end &&
                subscription.status === "active" ? (
                  <p className="next-billing">
                    Next billing on:{" "}
                    {dayjs
                      .unix(subscription.current_period_end)
                      .format("MMMM D, YYYY")}
                  </p>
                ) : null}
                {subscription.current_period_start &&
                subscription.status === "active" ? (
                  <p>
                    Started on:{" "}
                    {dayjs
                      .unix(subscription.current_period_start)
                      .format("MMMM D, YYYY")}
                  </p>
                ) : null}

                <Button
                  onClick={handleManageSubscriptions}
                  variant="contained"
                  color="primary"
                  className="subscribe-button upgrade"
                  fullWidth
                  endIcon={isLoading ? <CircularProgress size={18} /> : null}
                >
                  Manage Subscriptions
                </Button>
              </div>
            </div>
          </div>
        </Page>

        <BottomNavigationMobile />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Menu
        profile={profile}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        window={menuWindow}
        claims={currentUser.claims}
      />
      <Bar
        title={"Manage your plan"}
        actions={null}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Page>
        <div className="page-no-sidebar">
          <div className="subscription-plans">
            <div className="subscription-plan subscription-plan-premium">
              <h2>Pro</h2>
              <p>
                Bring your catalog sales campaigns to the next level by
                promoting only products that work.
              </p>
              <p className="price">$99</p>
              <div className="period">
                <small>Per month</small>
              </div>
              {/* <TextField
                id="discount"
                type="text"
                onChange={handleDiscountChange}
                value={discount}
                variant="outlined"
                fullWidth
                label="Discount Code"
              /> */}
              <Button
                onClick={handleCheckoutSession}
                variant="contained"
                color="primary"
                className="subscribe-button upgrade"
                fullWidth
                endIcon={isLoading ? <CircularProgress size={18} /> : null}
              >
                Get Started
              </Button>
              <ul>
                <li>
                  <div className="icon">
                    <CheckCircleIcon />
                  </div>{" "}
                  Manually remove products from campaigns.
                </li>
                <li>
                  <div className="icon">
                    <CheckCircleIcon />
                  </div>{" "}
                  Daily product set updates based on rules you define.
                </li>
                <li>
                  <div className="icon">
                    <CheckCircleIcon />
                  </div>{" "}
                  Advanced reporting and timeline comparison.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Page>

      <BottomNavigationMobile />
    </React.Fragment>
  );
};

export default withRouter(Plan);
