import React, { useEffect, useContext, useState } from "react";
import firebase from "./firebase";
import { AuthContext } from "../config/AuthProvider";
import NotificationMessage from "../components/NotificationMessage";
import { withSnackbar } from "notistack";

const NotificationProvider = (props) => {
  const { profile, currentUser } = useContext(AuthContext);
  const [notificationsToken, setNotificationsToken] = useState(null);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        setNotification({
          ...payload.notification,
          severity: payload.data.severity,
          in_app_click_action: payload.data.in_app_click_action,
          button_label: payload.data.button_label,
        });
      });
      messaging
        .getToken({
          vapidKey:
            "BHgVRdhzbBIWio42Mva57rFukrKXh-zSgsE3IFBSGpLqC_gRFxkQrC5pxi48NuKqlqyPYO43WivJYmk8OMBKH_Q",
        })
        .then((token) => {
          if (token) {
            setNotificationsToken(token);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();
    if (notificationsToken) {
      firestore
        .collection("tokens")
        .doc(profile.uid)
        .set({ notifications_token: notificationsToken }, { merge: true })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }, [notificationsToken]);

  useEffect(() => {
    if (notification) {
      props.enqueueSnackbar(notification, {
        persist: true,
      });
    }
  }, [notification]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default withSnackbar(NotificationProvider);
