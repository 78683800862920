import React from "react";
import { withRouter } from "react-router-dom";
import firebase from "../config/firebase";

const ReportSelectedAdAccount = (props) => {
  const { ad_account, uid, is_active, is_mobile } = props;
  const handleSelect = async () => {
    if (is_active) {
      props.history.push("/settings", {
        highlight: "ad-account",
      });
    } else {
      const functions = firebase.functions();
      const get_report_products = functions.httpsCallable(
        "get_report_products"
      );
      const get_report_metrics = functions.httpsCallable("get_report_metrics");
      const get_report_charts = functions.httpsCallable("get_report_charts");

      try {
        await firebase
          .firestore()
          .collection("reports")
          .doc(uid)
          .set({
            object: {
              ...ad_account,
              type: "ad_account",
              edge: ad_account.id,
              id: ad_account.account_id,
            },
            is_metrics_loading: true,
            is_charts_loading: true,
            is_products_loading: true,
            date_preset: "last_7d",
            cache: [],
            product: [],
            selected_product: null,
            updated_at: new Date(),
            uid: uid,
          });
        await get_report_products({
          object: {
            ...ad_account,
            id: ad_account.account_id,
            type: "ad_account",
            edge: ad_account.id,
          },
          date_preset: "last_7d",
          after: null,
        });
        await get_report_metrics({
          object: {
            ...ad_account,
            id: ad_account.account_id,
            type: "ad_account",
            edge: ad_account.id,
          },
          date_preset: "last_7d",
        });
        await get_report_charts({
          object: {
            ...ad_account,
            id: ad_account.account_id,
            type: "ad_account",
            edge: ad_account.id,
          },
          date_preset: "last_7d",
        });
      } catch (error) {
        console.log(error.toString());
      }
    }
  };

  return (
    <div
      className={
        is_active
          ? "report-object active report-ad-account"
          : "report-object report-ad-account"
      }
      onClick={handleSelect}
    >
      <h3 className="title">
        {ad_account.name}{" "}
        <small style={{ fontSize: "13px", opacity: ".6" }}>
          {ad_account.account_id}
        </small>
      </h3>
      <div className="subtitle">
        <span>
          <span className="account-status">Active</span>{" "}
          <span className="account-status-divider"></span>
          {ad_account.currency}
        </span>

        <img
          className="rounded-image"
          src={ad_account.picture_url}
          alt={ad_account.name}
          height="20"
        />
      </div>
    </div>
  );
};

export default withRouter(ReportSelectedAdAccount);
