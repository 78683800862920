import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      background: "#fafaff",
    },
    layoutGhost: {
      display: "flex",
    },
    drawerGhost: {
      width: "240px",
      flex: "0 0 240px",
      background: "#f1f1f8",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    contentGhost: {
      height: "100vh",
      width: "100%",
    },
    toolbarGhost: {
      height: "64px",
      width: "100%",
    },
    toolbarGhostLight: {
      background: "#f1f1f8",
    },
    toolbarGhostDark: {
      background: "#e0e0ed",
    },
  };
});

const Loading = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <LinearProgress />
      <div className={classes.layoutGhost}>
        <div className={classes.drawerGhost}>
          <div
            className={clsx(classes.toolbarGhost, classes.toolbarGhostDark)}
          ></div>
          <div
            className={clsx(classes.toolbarGhost, classes.toolbarGhostDark)}
          ></div>
        </div>
        <div className={classes.contentGhost}>
          <div
            className={clsx(classes.toolbarGhost, classes.toolbarGhostLight)}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
