import React, { useEffect, useState } from "react";
import Chart from "./Chart";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ordinal } from "../config/utils";

const ReportCharts = (props) => {
  const { data, metrics, profile, preset, isLoading, isMobile, report } = props;
  const [roasChart, setRoasChart] = useState([]);
  const [spendChart, setSpendChart] = useState([]);
  const [purchasesChart, setPurchasesChart] = useState([]);
  const [impressionsChart, setImpressionsChart] = useState([]);
  const [roasDifference, setRoasDifference] = useState(0);
  const [spendDifference, setSpendDifference] = useState(0);
  const [purchasesDifference, setPurchasesDifference] = useState(0);
  const [impressionsDifference, setImpressionsDifference] = useState(0);
  useEffect(() => {
    if (data.length > 0) {
      const roasMax = _.maxBy(data, function (o) {
        return parseFloat(o.purchase_roas);
      }).purchase_roas;
      const spendMax = _.maxBy(data, function (o) {
        return parseFloat(o.spend);
      }).spend;
      const purchasesdMax = _.maxBy(data, function (o) {
        return parseInt(o.purchases);
      }).purchases;
      const impressionsMax = _.maxBy(data, function (o) {
        return parseInt(o.impressions);
      }).impressions;
      const tempRoasChart = [];
      const tempSpendChart = [];
      const tempPurchasesChart = [];
      const tempImpressionsChart = [];
      data.forEach((i, index) => {
        tempRoasChart.push({
          day_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("ddd"),
          month_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMM"),
          month_year: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMMM, YYYY"),
          ordinal: `${ordinal(
            parseInt(
              dayjs(`${i.date} 12:00`)
                .tz(profile.selected_ad_account.timezone)
                .format("D")
            )
          )}`,
          full_date: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format(`dddd, MMM ${dayjs(i.date).format("D")}`),
          unit: "x",
          value: i.purchase_roas,
          max: roasMax,
        });
        tempSpendChart.push({
          day_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("ddd"),
          month_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMM"),
          month_year: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMMM, YYYY"),
          ordinal: `${ordinal(
            parseInt(
              dayjs(`${i.date} 12:00`)
                .tz(profile.selected_ad_account.timezone)
                .format("D")
            )
          )}`,
          full_date: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format(`dddd, MMM ${dayjs(i.date + " 12:00").format("D")}`),
          unit: i.account_currency,
          value: i.spend,
          max: spendMax,
        });
        tempPurchasesChart.push({
          day_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("ddd"),
          month_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMM"),
          month_year: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMMM, YYYY"),
          ordinal: `${ordinal(
            parseInt(
              dayjs(`${i.date} 12:00`)
                .tz(profile.selected_ad_account.timezone)
                .format("D")
            )
          )}`,
          full_date: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format(`dddd, MMM ${dayjs(i.date + " 12:00").format("D")}`),
          unit: "",
          value: i.purchases,
          max: purchasesdMax,
        });
        tempImpressionsChart.push({
          day_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("ddd"),
          month_name: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMM"),
          month_year: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format("MMMM, YYYY"),
          ordinal: `${ordinal(
            parseInt(
              dayjs(`${i.date} 12:00`)
                .tz(profile.selected_ad_account.timezone)
                .format("D")
            )
          )}`,
          full_date: dayjs(`${i.date} 12:00`)
            .tz(profile.selected_ad_account.timezone)
            .format(`dddd, MMM ${dayjs(i.date + " 12:00").format("D")}`),
          unit: "",
          value: parseInt(i.impressions),
          max: impressionsMax,
        });
      });

      setRoasChart(tempRoasChart);
      setSpendChart(tempSpendChart);
      setPurchasesChart(tempPurchasesChart);
      setImpressionsChart(tempImpressionsChart);
    }
  }, [data]);

  useEffect(() => {
    if (metrics && metrics.current) {
      const current_metrics = metrics.current;
      const previous_metrics = metrics.previous;
      if (previous_metrics) {
        setRoasDifference(
          parseFloat(current_metrics.purchase_roas) -
            parseFloat(previous_metrics.purchase_roas)
        );
        setSpendDifference(
          parseFloat(current_metrics.spend) - parseFloat(previous_metrics.spend)
        );
        setPurchasesDifference(
          parseFloat(current_metrics.purchases) -
            parseFloat(previous_metrics.purchases)
        );
        setImpressionsDifference(
          parseFloat(current_metrics.impressions) -
            parseFloat(previous_metrics.impressions)
        );
      } else {
        setRoasDifference(0);
        setSpendDifference(0);
        setPurchasesDifference(0);
        setImpressionsDifference(0);
      }
    }
  }, [metrics]);
  return (
    <React.Fragment>
      <Chart
        data={roasChart}
        date_preset={preset && preset}
        color={
          roasDifference > 0
            ? "#34c759"
            : roasDifference < 0
            ? "#ff3c30"
            : "#79769b"
        }
        title="ROAS"
        isLoading={isLoading}
        isMobile={isMobile}
      />
      <Chart
        data={spendChart}
        date_preset={preset && preset}
        color={
          spendDifference > 0
            ? "#34c759"
            : spendDifference < 0
            ? "#ff3c30"
            : "#79769b"
        }
        title="Spend"
        isLoading={isLoading}
        isMobile={isMobile}
      />
      <Chart
        data={purchasesChart}
        date_preset={preset && preset}
        color={
          purchasesDifference > 0
            ? "#34c759"
            : purchasesDifference < 0
            ? "#ff3c30"
            : "#79769b"
        }
        title="Purchases"
        isLoading={isLoading}
        isMobile={isMobile}
      />
      <Chart
        data={impressionsChart}
        date_preset={preset && preset}
        color={
          impressionsDifference > 0
            ? "#34c759"
            : impressionsDifference < 0
            ? "#ff3c30"
            : "#79769b"
        }
        title="Impressions"
        isLoading={isLoading}
        isMobile={isMobile}
      />
    </React.Fragment>
  );
};

export default ReportCharts;
