import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const Notification = (props) => {
  const { notification, handleCloseNotification } = props;

  if (notification) {
    return (
      <Snackbar
        open={notification.status}
        autoHideDuration={notification.timeout}
        onClose={handleCloseNotification}
      >
        <Alert severity={notification.severity}>{notification.message}</Alert>
      </Snackbar>
    );
  }

  return null;
};

export default Notification;
