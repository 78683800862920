import React from "react";
import Avatar from "@material-ui/core/Avatar";

const UserAvatar = (props) => {
  const { profile, showEmail } = props;
  return (
    <div className="avatar-facebook">
      <Avatar
        variant="rounded"
        className="image"
        alt={profile.fullName}
        src={profile.picture.url}
      />
      <span>
        <span>{profile.full_name}</span>
        {showEmail ? <span>{profile.email}</span> : null}
      </span>
    </div>
  );
};

export default UserAvatar;
