import React from "react";
import Toolbar from "@material-ui/core/Toolbar";

const Page = (props) => {
  const { children } = props;
  return (
    <React.Fragment>
      <div className="page">
        <Toolbar className="fake-toolbar" />
        <div className="page-content">{children}</div>
      </div>
    </React.Fragment>
  );
};

export default Page;
