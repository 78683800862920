import React, { useState, useRef, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import { withRouter } from "react-router-dom";
import firebase from "../config/firebase";
import DatePreset from "./DatePreset";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Info from "./Info";
import { date_preset_name } from "../config/utils";

const EditCampaignForm = (props) => {
  const id = props.match.params.id;
  const { campaign, profile } = props;
  const [status, setStatus] = useState("PAUSED");
  const [preset, setPreset] = useState("");
  const [impressions, setImpressions] = useState(0);
  const [roas, setRoas] = useState(0);
  const [dailyBudget, setDailyBudget] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: "",
    severity: "success",
    timeout: 12000,
    open: false,
  });

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (campaign && !campaign.is_loading && !campaign.is_empty) {
      setStatus(campaign.status);
      setPreset(campaign.rules.date_preset);
      setImpressions(campaign.rules.impressions);
      setRoas(campaign.rules.roas);
      setDailyBudget(campaign.ad_set.daily_budget / 100);
      setBidAmount(campaign.ad_set.bid_amount / 100);
    }
  }, [campaign]);

  const handleUserMessageClose = () => {
    setUserMessage({ message: "", severity: "", open: false });
  };

  const handleStatusChange = (event) => {
    setShouldSave(true);
    if (event.target.checked) {
      setStatus("ACTIVE");
    } else {
      setStatus("PAUSED");
    }
  };

  const handlePresetChange = (preset) => {
    setPreset(preset);
    setShouldSave(true);
  };

  const handleChangeImpressions = (event) => {
    setImpressions(event.target.value);
    setShouldSave(true);
  };

  const handleChangeRoas = (event) => {
    setRoas(event.target.value);
    setShouldSave(true);
  };

  const handleChangeDailyBudget = (event) => {
    setDailyBudget(event.target.value);
    setShouldSave(true);
  };

  const handleChangeBidAmount = (event) => {
    setBidAmount(event.target.value);
    setShouldSave(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (componentIsMounted.current) {
      setIsLoading(true);
    }

    if (
      parseInt(impressions) > 0 &&
      parseInt(roas) > 0 &&
      parseInt(dailyBudget) > 0 &&
      parseInt(bidAmount) > 0
    ) {
      //   console.log(campaign, {
      //     status: status,
      //     date_preset: preset,
      //     impressions: impressions,
      //     roas: roas,
      //     bid_amount: bidAmount,
      //     daily_budget: dailyBudget,
      //     campaign_id: campaign.id,
      //   });
      try {
        const functions = firebase.functions();
        const update_campaign = functions.httpsCallable("update_campaign");
        const campaignResponse = await update_campaign({
          status: status,
          date_preset: preset,
          impressions: impressions,
          roas: roas,
          bid_amount: bidAmount,
          daily_budget: dailyBudget,
          campaign_id: campaign.id,
        });

        console.log(campaignResponse.data);

        if (componentIsMounted.current) {
          setIsLoading(false);
          setShouldSave(false);
          setUserMessage({
            message: `Camapign with id ${campaign.campaign_fb_id} has been successfully updated!`,
            severity: "success",
            timeout: 5000,
            open: true,
          });
        }
      } catch (error) {
        if (componentIsMounted.current) {
          setIsLoading(false);
          setUserMessage({
            message: error.message,
            severity: "error",
            timeout: 5000,
            open: true,
          });
        }
      }
    } else {
      if (componentIsMounted.current) {
        setIsLoading(false);
        setUserMessage({
          message: "Please check the values and try again!",
          severity: "warning",
          timeout: 5000,
          open: true,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl variant="outlined" fullWidth>
        <div className="campaign-status">
          <h4>Status</h4>
          <div>
            <Switch
              checked={status === "ACTIVE"}
              onChange={handleStatusChange}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <p>{status === "ACTIVE" ? "Active" : "Paused"}</p>
          </div>
        </div>
      </FormControl>
      <h3>
        Date Preset{" "}
        <Info
          html={
            <React.Fragment>
              <p>Select the time frame we apply Impressions/ROAS rules.</p>
            </React.Fragment>
          }
        />
      </h3>
      <DatePreset
        classes="justify-flex-start"
        handlePresetChange={handlePresetChange}
        preset={preset}
        isLoading={false}
      />
      <h3>
        Rules{" "}
        <Info
          html={
            <React.Fragment>
              <p>
                Ex: if {preset === "this_year" ? "" : "in the"}{" "}
                <span style={{ textTransform: "lowercase" }}>
                  {date_preset_name(preset)}
                </span>{" "}
                the number of impressions is grater than {impressions} but the
                ROAS is smaller than {roas}x we consider the product
                unprofitable and remove it from the product set.
              </p>
            </React.Fragment>
          }
        />
      </h3>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="daily-budget">Impressions</InputLabel>
        <OutlinedInput
          id="impressions"
          value={impressions}
          onChange={handleChangeImpressions}
          labelWidth={90}
          type="number"
        />
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="roas">ROAS</InputLabel>
        <OutlinedInput
          id="roas"
          value={roas}
          onChange={handleChangeRoas}
          labelWidth={48}
          type="number"
        />
      </FormControl>
      <h3>
        Daily Budget{" "}
        <Info
          html={
            <React.Fragment>
              <p>
                If you choose a daily budget, you may spend more on some days
                and less on others, but will maintain an average daily budget.
              </p>
            </React.Fragment>
          }
        />
      </h3>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="daily-budget">Daily Budget</InputLabel>
        <OutlinedInput
          id="daily-budget"
          value={dailyBudget}
          onChange={handleChangeDailyBudget}
          endAdornment={
            <InputAdornment position="start">
              {campaign.ad_account.currency}
            </InputAdornment>
          }
          type="number"
          labelWidth={95}
        />
      </FormControl>
      <h3>
        Bid Amount{" "}
        <Info
          html={
            <React.Fragment>
              <p>Set a bid to control your maximum bid in every auction.</p>
            </React.Fragment>
          }
        />
      </h3>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="bid-amount">Bid Amount</InputLabel>
        <OutlinedInput
          id="bid-amount"
          value={bidAmount}
          onChange={handleChangeBidAmount}
          endAdornment={
            <InputAdornment position="start">
              {campaign.ad_account.currency}
            </InputAdornment>
          }
          type="number"
          labelWidth={90}
        />
      </FormControl>
      <br />
      <br />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!shouldSave || isLoading}
      >
        Update
        {isLoading ? <CircularProgress size={18} /> : null}
      </Button>
      <Snackbar
        open={userMessage.open}
        autoHideDuration={userMessage.timeout}
        onClose={handleUserMessageClose}
      >
        <Alert onClose={handleUserMessageClose} severity={userMessage.severity}>
          {userMessage.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default withRouter(EditCampaignForm);
