import React, { useState, useEffect } from "react";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import Tippy from "@tippyjs/react/headless";
import { useSpring, motion } from "framer-motion";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const Box = styled(motion.div)``;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ReportItem = (props) => {
  const {
    product,
    productToEdit,
    index,
    isSelected,
    isEditMode,
    removed,
    automaticallyRemoved,
    handleProductToEdit,
    handleProductSelect,
    isLoading,
    handleProductRemove,
    handleProductAdd,
    profile,
  } = props;
  const [scopedIsLoading, setScopedIsLoading] = useState(false);
  const difference = product.previous
    ? parseFloat(product.current.purchase_roas) -
      parseFloat(product.previous.purchase_roas)
    : parseFloat(product.current.purchase_roas);
  const impressions_difference = product.previous
    ? parseInt(product.current.impressions) -
      parseInt(product.previous.impressions)
    : parseInt(product.current.impressions);

  const handleClickAway = () => {
    if (productToEdit === product.id) {
      handleProductToEdit("");
    }
  };
  const springConfig = { damping: 15, stiffness: 300 };
  const initialScale = 0.5;
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(initialScale, springConfig);

  const onMount = () => {
    scale.set(1);
    opacity.set(1);
  };

  const onHide = ({ unmount }) => {
    const cleanup = scale.onChange((value) => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });

    scale.set(initialScale);
    opacity.set(0);
  };

  useEffect(() => {
    setScopedIsLoading(false);
  }, [product]);

  console.log("Props", props);

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <li
          className={
            isSelected
              ? removed
                ? `item product-${index} selected removed automatically-removed-${
                    automaticallyRemoved ? true : false
                  }`
                : `item product-${index} selected automatically-removed-${
                    automaticallyRemoved ? true : false
                  }`
              : removed
              ? `item product-${index} removed automatically-removed-${
                  automaticallyRemoved ? true : false
                }`
              : `item product-${index} automatically-removed-${
                  automaticallyRemoved ? true : false
                }`
          }
          onClick={
            isLoading || isEditMode
              ? null
              : (event) => {
                  handleProductSelect(product.id);
                }
          }
          style={
            isEditMode && productToEdit == product.id
              ? { transform: "translateX(-100px)" }
              : { transform: "none" }
          }
        >
          {removed ? (
            <div className="removed-product-icon">
              <Tippy
                render={(attrs) => (
                  <Box
                    className="info-box"
                    tabIndex="-1"
                    style={{ scale, opacity }}
                    {...attrs}
                  >
                    Removed manually on{" "}
                    {dayjs
                      .unix(removed.removed_at.seconds)
                      .tz(profile.selected_ad_account.timezone)
                      .format("dddd, D MMMM")}{" "}
                    at{" "}
                    {dayjs
                      .unix(removed.removed_at.seconds)
                      .tz(profile.selected_ad_account.timezone)
                      .format("HH:MM A")}{" "}
                  </Box>
                )}
                animation={true}
                onMount={onMount}
                onHide={onHide}
                interactive={true}
                hideOnClick={true}
                trigger={"mouseenter focus"}
              >
                <VisibilityOffIcon />
              </Tippy>
            </div>
          ) : null}
          {automaticallyRemoved ? (
            <div className="automatically-removed-product-icon">
              <Tippy
                render={(attrs) => (
                  <Box
                    className="info-box"
                    tabIndex="-1"
                    style={{ scale, opacity }}
                    {...attrs}
                  >
                    Removed automatically on{" "}
                    {dayjs
                      .unix(automaticallyRemoved.removed_at.seconds)
                      .tz(profile.selected_ad_account.timezone)
                      .format("dddd, D MMMM")}{" "}
                    at{" "}
                    {dayjs
                      .unix(automaticallyRemoved.removed_at.seconds)
                      .tz(profile.selected_ad_account.timezone)
                      .format("HH:MM A")}{" "}
                    ({automaticallyRemoved.rule})
                  </Box>
                )}
                animation={true}
                onMount={onMount}
                onHide={onHide}
                interactive={true}
                hideOnClick={true}
                trigger={"mouseenter focus"}
              >
                <FlipCameraAndroidIcon />
              </Tippy>
            </div>
          ) : null}
          {isEditMode && productToEdit === product.id ? (
            removed || automaticallyRemoved ? (
              <Button
                className={
                  scopedIsLoading
                    ? "loading-sidebar-product"
                    : "add-sidebar-product"
                }
                onClick={() => {
                  setScopedIsLoading(true);
                  handleProductAdd(product.id);
                }}
              >
                {scopedIsLoading ? <CircularProgress size={26} /> : "Add"}
              </Button>
            ) : (
              <Button
                className={
                  scopedIsLoading
                    ? "loading-sidebar-product"
                    : "remove-sidebar-product"
                }
                onClick={() => {
                  setScopedIsLoading(true);
                  handleProductRemove(product);
                }}
              >
                {scopedIsLoading ? <CircularProgress size={26} /> : "Remove"}
              </Button>
            )
          ) : null}
          <div className="item-wrapper">
            {isEditMode ? (
              <div className="edit-button">
                {removed || automaticallyRemoved ? (
                  <Fab
                    size="small"
                    className="add-intent-sidebar-product"
                    onClick={() => {
                      handleProductToEdit(product.id);
                    }}
                  >
                    <AddCircleIcon />
                  </Fab>
                ) : (
                  <Fab
                    size="small"
                    className="remove-intent-sidebar-product"
                    onClick={() => {
                      handleProductToEdit(product.id);
                    }}
                  >
                    <RemoveCircleIcon />
                  </Fab>
                )}
              </div>
            ) : null}

            {product.id.split(",")[1] ? (
              <div className="title">
                <div className="title-content">{product.id.split(",")[1]}</div>
                <div className="subtitle">
                  <span>ID: {product.id.split(",")[0]}</span>
                </div>
              </div>
            ) : (
              <div className="title">
                <span className="title-content">{product.id}</span>
              </div>
            )}
            <div className="metric impressions">
              <span className="current">
                {numberWithCommas(product.current.impressions)}
              </span>
              {impressions_difference > 0 ? (
                <span className="difference positive">
                  +{numberWithCommas(impressions_difference)}
                </span>
              ) : impressions_difference != 0 ? (
                <span className="difference negative">
                  {numberWithCommas(impressions_difference)}
                </span>
              ) : (
                <span className="difference neutral">
                  {impressions_difference}
                </span>
              )}
            </div>
            <div className="metric roas">
              <span className="current">{product.current.purchase_roas}</span>
              {difference > 0 ? (
                <span className="difference positive">
                  +{difference.toFixed(2)}
                </span>
              ) : difference != 0 ? (
                <span className="difference negative">
                  {difference.toFixed(2)}
                </span>
              ) : (
                <span className="difference neutral">
                  {difference.toFixed(0)}
                </span>
              )}
            </div>
          </div>
        </li>
      </ClickAwayListener>
    </React.Fragment>
  );
};

export default ReportItem;
